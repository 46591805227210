import axios from "axios";
import { apiData } from "./useCommon";
import { useCommonStore, useMailStore } from "@/store";
import { ElMessage, ElMessageBox } from "element-plus";


export const getMailList = async (page) => {

    const store = useMailStore();
    const { userToken, apiBaseurl } = apiData();

    axios({
        method: 'get',
        url: apiBaseurl + '/email/list',
        headers: {
            Authorization: "Bearer "+userToken
        },
        params: {
            page: page
        }
    }).then(response => {
        const result = response.data.Result;

        store.total_items = result.total_items;
        store.email_data = result.items;

    }).catch(error => {
        const error_msg = error.response.data;

        ElMessage({
            showClose: true,
            type: "error",
            message: error_msg.Status.Desc,
            duration: 1500
        });

    })

}

export const getMailDetail = async (email_id) => {

    const store = useMailStore();
    const { userToken, apiBaseurl } = apiData();

    axios({
        method: 'get',
        url: apiBaseurl + '/email/detail',
        headers: {
            Authorization: "Bearer "+ userToken
        },
        params: {
            email_id: email_id
        }
    })
    .then(response => {
        store.email_detail = response.data.Result;
    })
    .catch(error => {
        const error_msg = error.response.data;
        ElMessage({
            showClose: true,
            message: error_msg.Status.Desc,
            type: 'error'
        });
    })
}

export const sendMail = async () => {

    const store = useMailStore();
    const { userToken, apiBaseurl } = apiData();

    await axios({
        method: 'post',
        url: apiBaseurl + '/email/create',
        headers: {
            Authorization: "Bearer "+ userToken
        },
        data: {
            to_user_id: parseInt(store.send_data.user_id),
            title: store.send_data.title,
            content: store.send_data.content
        }
    }).then(response => {
        const result = response.data;

        const awards_list = response.data.Result.awards_list;
        if (awards_list.length > 0) {
            const commonStore = useCommonStore();
            commonStore.awards.data = awards_list;
            commonStore.awards.toggle = !commonStore.awards.toggle;
        }

        ElMessage({
            showClose: true,
            message: result.Status.Desc,
            type: 'success'
        });

        store.send_data.user_id = '';
        store.send_data.title = '';
        store.send_data.content = '';
        // this.scroll_value = '';
    }).catch(error => {
        const error_msg = error.response.data;

        ElMessage({
            showClose: true,
            type: "error",
            message: error_msg.Status.Desc,
            duration: 1500
        });
    })

}

export const readMail = async (multipleSelection) => {

    const store = useMailStore();

    let email_id_list = [];
    multipleSelection.forEach(item => email_id_list.push(item.email_id));

    email_id_list.forEach(item => {
        const objIndex = store.email_data.findIndex((obj => obj.email_id == item));
        store.email_data[objIndex].email_info.isRead = true;
    });

    const { userToken, apiBaseurl } = apiData();

    await axios({
        method: 'post',
        url: apiBaseurl + '/email/isread',
        headers: {
            Authorization: "Bearer "+ userToken
        },
        data: {
            email_id_list: email_id_list
        }
    }).catch(error => {
        const error_msg = error.response.data;
        ElMessage({
            showClose: true,
            message: error_msg.Status.Desc,
            type: 'error',
            duration: 1500
        });
    })

}

export const deleteMail = async (multipleSelection, currentPage) => {

    let email_id_list = []
    multipleSelection.forEach(item => email_id_list.push(item.email_id));

    const store = useMailStore();

    await ElMessageBox.confirm(
        '您將刪除'+email_id_list.length+'則信件，是否繼續？',
        '刪除信件',
        {
            confirmButtonText: '確認',
            cancelButtonText: '取消',
            confirmButtonClass: 'el-button--coffee',
            type: 'warning'
        }
    ).then(async () => {

        const { userToken, apiBaseurl } = apiData();

        await axios({
            method: 'post',
            url: apiBaseurl + '/email/delete',
            headers: {
                Authorization: "Bearer "+userToken
            },
            data: {
                email_id_list: email_id_list
            }
        }).then(() => {
            store.email_data = store.email_data.filter(item => email_id_list.includes(item.email_id));

            if (store.email_data.length == 0) {

                if (currentPage > 1) {
                    currentPage -= 1;
                } else {
                    currentPage = 1;
                }

            }
            getMailList(currentPage);

        }).catch(error => {
            const error_msg = error.response.data;

            ElMessage({
                showClose: true,
                message: error_msg.Status.Desc,
                type: 'error',
                duration: 1500
            });
        })

    });

    return currentPage;

}

export const replyMail = async (replyData) => {

    const { userToken, apiBaseurl } = apiData();

    await axios({
        method: 'post',
        url: apiBaseurl + '/email/reply',
        headers: {
            Authorization: "Bearer "+ userToken
        },
        data: {
            reply_email_id: replyData.email_id,
            title: replyData.title,
            content: replyData.content
        }
    }).then(response => {
        const result = response.data;
        ElMessage({
            showClose: true,
            message: result.Status.Desc,
            type: 'success',
            duration: 1500
        });
    }).catch(error => {
        const error_msg = error.response.data;

        ElMessage({
            showClose: true,
            message: error_msg.Status.Desc,
            type: 'error',
            duration: 1500
        });
    })

}