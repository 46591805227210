<template>
    <common-modal ref="redeemCodeModal" :can-close="true" :tit="$t('modal.redeemCode.tit')" class="md">
        <template v-slot:infoArea>
            <el-form label-position="top" class="redeem-modal margin-top-30">
                <el-form-item>
                    <el-input v-model="redeemCodeValue" :placeholder="$t('modal.redeemCode.placeholder')"></el-input>
                </el-form-item>
            </el-form>
            <el-button type="coffee" class="blockBtn margin-bottom-30" style="font-size: 16px; height: 40px;" @click="onSubmit();">{{ $t('modal.redeemCode.submitBtn') }}</el-button>
        </template>
    </common-modal>
</template>

<script>
import CommonModal from '../CommonModal.vue';
import { ref } from 'vue';
import { redeemCode } from '@/composables';

export default {
    name: 'redeemCodeModal',
    props: {
        toggle: {
            default: false,
            required: true
        }
    },
    components: {
        CommonModal
    },
    setup(props) {
        const redeemCodeValue = ref(null);

        const onSubmit = async () => {
            await redeemCode(redeemCodeValue.value);
            redeemCodeValue.value = '';
        }

        return {
            redeemCodeValue,
            onSubmit
        }
    },
    watch: {
        toggle: function () {
            this.toggleModal('redeemCodeModal');
        }
    },
    methods: {
        toggleModal: function (t) {
            this.$refs[t].toggle = !this.$refs[t].toggle;
        },
    }
}

</script>

<style>
.el-form-item__content .el-input .el-input__wrapper {
    padding: 1px 0px;
    font-size: 18px;
}

.redeem-modal .el-input__inner {
    height: 42px!important;
    line-height: 42px!important;
}
</style>