import { useCommonStore, useShareStore, useUserStore } from "@/store";
import { ElMessage } from "element-plus";
import { apiData } from "./useCommon";
import axios from "axios";

export const shareLink = async (type, category, id, amount = 0) => {

    const commonStore = useCommonStore();
    const localeSetting = commonStore.localeSetting;

    let middleUrl;
    if (localeSetting == 'en') {
        middleUrl = `https://www.tdtn.xyz/${category}`
    } else {
        middleUrl = `https://www.tdtn.tw/${category}`
    }
    // middleUrl = `http://localhost:8080/${category}`

    if (category == 'rewards') {

        middleUrl += '?game_id=';
        middleUrl += id.toString();
        
        const code = await generateShareKey(id);
        middleUrl += `%26code=${code}`;

    } else {
        middleUrl += '/';
        middleUrl += id.toString();
    }

    if (type == 'facebook') {

        const prefixUrl = 'https://www.facebook.com/sharer/sharer.php?u=';
        const url = prefixUrl + middleUrl;
        window.open(url);

    } else if (type == 'line') {

        const prefixUrl = 'https://social-plugins.line.me/lineit/share?url=';
        const url = prefixUrl + middleUrl + '?openExternalBrowser=1';
        window.open(url);

    } else if (type == 'twitter') {

        const prefixUrl = 'http://twitter.com/share?';
        const text = "text=Come and win $Bonk on TradingTrain!%0A@bonk_inu 🤝 @trading_train%0A%0A%23Bonk %23TradingTrain %23LearnToEarn%0A";
        const url = `&url=${middleUrl}`
        window.open(prefixUrl+text+url);

    } else if (type == 'link') {

        middleUrl = middleUrl.split('%26code=')[0];

        const url = middleUrl + '?openExternalBrowser=1';
        navigator.clipboard.writeText(url);

        let copyMsg;
        if (localeSetting == 'en') {
            copyMsg = 'Link copied'
        } else {
            copyMsg = '已複製連結'
        }

        ElMessage({
            message: copyMsg,
            duration: 500,
            type: "success",
            showClose: true
        });

    }

}

export const generateShareKey = async (game_id) => {

    const { userToken, apiBaseurl } = apiData();

    const shareCode = await axios({
        method: "get",
        url: apiBaseurl + '/share/generate',
        headers: {
            Authorization: "Bearer "+ userToken
        },
        params: {
            game_id: game_id
        }
    }).then(response => {
        return response.data.Result;
    }).catch(err => {
        console.log(err.response.data);
    });

    return shareCode;
}

export const exchangeRewards = async (code, bonkToggle) => {

    const { userToken, apiBaseurl } = apiData();

    await axios({
        method: "post",
        url: apiBaseurl + "/share/exchange",
        headers: {
            Authorization: "Bearer "+ userToken
        },
        data: {
            code: code
        }
    }).then(() => {
        bonkToggle.value = !bonkToggle.value;
    }).catch(error => {
        console.log(error);
    })

}

export const getShareRewardList = async (page) => {

    const { userToken, apiBaseurl } = apiData();
    const shareStore = useShareStore();
    const nPerpage = shareStore.pageQty;

    await axios({
        method: "get",
        url: apiBaseurl + "/share/reward/list",
        headers: {
            Authorization: "Bearer "+ userToken
        },
        params: {
            page: page,
            nPerpage: nPerpage
        }
    }).then(response => {

        const result = response.data.Result;
        shareStore.reward_list_data = result.items;
        shareStore.total_items = result.total_items;

    }).catch(error => {
        console.log(error);
    })

}