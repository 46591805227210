import axios from "axios";
import { useCommonStore, useHomePageStore, useUserStore } from "@/store";
import { apiData } from "./useCommon";

export const getHomePage = async (isHome) => {

    const userStore = useUserStore();
    const commonStore = useCommonStore();
    const homePageStore = useHomePageStore();
    const { userToken, apiBaseurl } = apiData();

    await axios({
        method: "get",
        url: apiBaseurl + "/home",
        headers: {
            Authorization: "Bearer "+ userToken
        },
        params: {
            isHome: isHome,
            mode: commonStore.localeSetting
        }
    }).then(response => {
        const result = response.data.Result;

        const userData = result.user_data;
        userStore.user_id = userData.user_id;
        userStore.username = userData.username;
        userStore.in_game = userData.in_game;
        userStore.level = userData.level;
        userStore.asset_level = userData.asset_level;
        userStore.total_asset = userData.total_asset;
        userStore.convert_asset = userData.convert_asset;
        userStore.portfolio = userData.portfolio;
        userStore.convert_portfolio = userData.convert_portfolio;
        userStore.profile_picture = userData.profile_picture;
        userStore.rank = userData.rank;
        userStore.tt_coin = userData.tt_coin;

        homePageStore.article_data = result.article_data;
        homePageStore.rank_data = result.rank_data;

    }).catch(error => {
        console.log(error.response.data);
    });
}