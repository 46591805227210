import axios from "axios";
import { apiData } from "./useCommon";
import { useCommonStore, useCompetitionStore, useGameStore } from "@/store";
import { ElMessage } from "element-plus";

export const getCompetitionList = async () => {

    const { apiBaseurl, userToken } = apiData();

    await axios({
        url: apiBaseurl + "/competition/list",
        method: "get",
        headers: {
            Authorization: "Bearer "+ userToken
        }
    }).then(response => {

        const result = response.data.Result;

        const store = useCompetitionStore();
        store.list = result;

    }).catch(error => {
        console.log(error.response.data);
    });

}

export const joinCompetition = async (competitionCode, inviteCode) => {

    const { apiBaseurl, userToken } = apiData();

    await axios({
        url: apiBaseurl + "/competition/join",
        method: "post",
        headers: {
            Authorization: "Bearer "+ userToken
        },
        data: {
            competitionCode: competitionCode,
            inviteCode: inviteCode
        }
    }).then(response => {

        const result = response.data.Result;
        const competitionId = result.ID;
        const enabled = result.enabled;

        if (enabled) {
            window.location.href = '/competition/info/'+ competitionId;
        } else {
            window.location.reload();
        }

    }).catch(error => {

        const error_msg = error.response.data;
        ElMessage({
            showClose: true,
            type: "error",
            message: error_msg.Status.Desc,
            duration: 1500
        });

    });

}

export const competitionInfo = async (id) => {

    const { apiBaseurl, userToken } = apiData();
    const store = useCompetitionStore();

    const localeSetting = window.$cookies.get('localeSetting');

    await axios({
        url: apiBaseurl + "/competition/info",
        method: "get",
        headers: {
            Authorization: "Bearer "+ userToken
        },
        params: {
            id: id,
            lang: localeSetting
        }
    }).then(response => {

        const userInfo = response.data.Result;
        store.user_info = userInfo;

    }).catch(() => {

        window.location.href = '/competition/home';

    });

}

export const competitionRank = async (id) => {

    const { apiBaseurl, userToken } = apiData();
    const store = useCompetitionStore();

    const localeSetting = window.$cookies.get('localeSetting');

    await axios({
        url: apiBaseurl + "/competition/rank",
        method: "get",
        headers: {
            Authorization: "Bearer "+ userToken
        },
        params: {
            id: id,
            lang: localeSetting
        }
    }).then(response => {

        const rankData = response.data.Result;
        store.rank_data = rankData;

    }).catch(error => {

        const error_msg = error.response.data;
        ElMessage({
            showClose: true,
            type: "error",
            message: error_msg.Status.Desc,
            duration: 1500
        });

    });

}

export const competitionRankDetail = async (id, page, isTicket = false) => {

    const { userToken, apiBaseurl } = apiData();

    const competitionStore = useCompetitionStore();
    const nPerpage = competitionStore.pageQty;

    const commonStore = useCommonStore();
    const localeSetting = commonStore.localeSetting;

    await axios({
        method: "get",
        url: apiBaseurl + '/competition/rank/detail',
        headers: {
            Authorization: "Bearer "+ userToken
        },
        params: {
            page: page,
            nPerpage: nPerpage,
            id: id,
            isTicket: isTicket,
            lang: localeSetting
        }
    }).then(response => {

        const result = response.data.Result;

        competitionStore.rank_data = result.items;
        competitionStore.total_items = result.total_items;

        if (page == 1) {
            document.title = result.pageTitle;
        }

    }).catch(error => {

        console.log(error);
        history.back();

    });



}

// 查看是否有足夠現金遊玩遊戲
export const checkCompetitionPortfolioRule = (applyLoanToggle, gameMode, competitionId) => {

    const competitionStore = useCompetitionStore();
    const userInfo = competitionStore.user_info;

    const gameStore = useGameStore();
    const game_result = gameStore.game_result

    let portfolio = userInfo.portfolio;
    if (!portfolio) {
        portfolio = game_result.portfolio;
    }

    if (portfolio < 100000) {
        applyLoanToggle.value = !applyLoanToggle.value;
    } else {
        window.location.href = '/competition/game?mode=' + gameMode + '&competition_id=' + competitionId;
    }

}