import { useWallet } from "solana-wallets-vue";
import { Connection, clusterApiUrl } from "@solana/web3.js";
import { useCommonStore } from "@/store";

let workspace = null;

export const useWorkspace = () => workspace;

export const initWorkspace = () => {

    const store = useCommonStore();
    const network = store.solanaNetwork;

    let clusterUrl = clusterApiUrl(network);
    if (network == 'mainnet-beta') {
        clusterUrl = 'https://icy-morning-waterfall.solana-mainnet.quiknode.pro/1d4818ff9d1be4c175581c59ddcff56efd9731e6/';
        // clusterUrl = 'https://api.metaplex.solana.com/';
    }

    // clusterUrl = 'https://swr.xnfts.dev/rpc-proxy/';

    const wallet = useWallet();
    const connection = new Connection(clusterUrl, "confirmed");

    workspace = {
        wallet,
        connection
    }
}