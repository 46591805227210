<template>
    <div>

        <section class="item">
            <div class="itemBlock">

                <a href="https://discord.gg/FAm4Kerg9e" title="廣告" target="blank_">
                    <img src="../assets/images/ads/discord_ads.png" alt="" class="adBanner">
                </a>
            </div>
        </section>

        <!-- <section class="item" v-if="localeSetting == 'zh-TW'">
            <div class="itemBlock">

                <a href="https://play.google.com/store/apps/details?id=tdtn.tw.tradingtrain_application" title="廣告" target="blank_">
                    <img src="../assets/images/鎚圈APP下載.png" alt="" class="adBanner">
                </a>
            </div>
        </section> -->

        <section class="item" v-if="localeSetting == 'zh-TW'">
            <div class="itemBlock">
                <a href="https://line.me/ti/g2/9_HdgR1h2iipbq8oJxGbcAWxmifBNu_aKmZ9LA?utm_source=invitation&utm_medium=link_copy&utm_campaign=default" title="廣告" target="blank_">
                    <img src="../assets/images/加入鎚圈Line社群.png" alt="" class="adBanner">
                </a>
            </div>
        </section>

    </div>
</template>

<script>
import { useCommonStore } from "@/store";
import { computed } from "vue";
// @ is an alias to /src
export default {
    name: "AdArea",
    setup() {
        const commonStore = useCommonStore();
        const localeSetting = computed(() => commonStore.localeSetting);

        return {
            localeSetting
        };
    }
    // mounted() {
    //     this.CheckAds()
    // },
    // methods: {
    //     CheckAds() {
    //         var header_ads = document.getElementsByClassName('adsbygoogle')[0]
            
    //         if (!header_ads.dataset.adStatus) {
    //             setTimeout(() => {
    //                 this.CheckAds()
    //             }, 1000)
    //         } else {
    //             var adsItem_list = document.getElementsByClassName('adsItem');
    //             var adsItem_keys = Object.keys(adsItem_list);

    //             adsItem_keys.forEach((key) => {
    //                 var item = adsItem_list[key].getElementsByClassName('adsbygoogle')[0];
    //                 if (item.dataset.adStatus == 'unfilled') {
    //                     item.parentNode.parentNode.style.display = 'none';
    //                 }
    //             })
    //         }
    //     },
    // }
};
</script>

<style scoped>

@media screen and (max-width: 475px) {
    .adsItem {
        padding-top: 0!important;
        padding-bottom: 0!important;
        background-color: transparent;
    }
}

</style>