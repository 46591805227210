<template>
    <common-modal ref="selectFuturesMode" :can-close="true" :tit="tit" class="lg">
        <template v-slot:infoArea>
            <el-carousel style="margin-top: 20px; margin-bottom: 30px;" :trigger="'click'" :autoplay="false" type="card"
                height="200px" :initial-index="0" indicator-position="none" @change="setFuturesGameMode">
                <el-carousel-item v-for="item in futuresMode" :key="item">
                    <h3 text="2xl" justify="center">{{ item }}</h3>
                </el-carousel-item>
            </el-carousel>
            <div class="btn gameStartBtn fixedBottomBtn futureStartBtn">
                <a href="javascript:void(0)" title="開始遊戲" @click="onStartGame(futuresGameMode)"> {{
                    $t('gameLobby.freeMode.startGameBtn') }} </a>
            </div>
        </template>
    </common-modal>

    <common-modal ref="selectCryptoMode" :can-close="true" :tit="tit" class="lg">
        <template v-slot:infoArea>
            <div class="btn gameStartBtn fixedBottomBtn futureStartBtn">
                <a href="javascript:void(0)" title="開始遊戲" @click="onStartGame(cryptoGameMode)"> {{
                    $t('gameLobby.freeMode.startGameBtn') }} </a>
            </div>
            <el-carousel style="margin-top: 20px;  margin-bottom: 30px;" :trigger="'click'" :autoplay="false" type="card"
                height="200px" :initial-index="0" indicator-position="none" @change="setCryptoGameMode">
                <el-carousel-item v-for="item in cryptoMode" :key="item">
                    <h3 text="2xl" justify="center">{{ item }}</h3>
                </el-carousel-item>
            </el-carousel>
        </template>
    </common-modal>

    <common-modal ref="selectNqMode" :can-close="true" :tit="tit" class="lg nq-mode-area">
        <template v-slot:infoArea>
            <el-row :gutter="24">
                <el-col :md="12" class="drawer-col">
                    <h3 class="drawer-title">Choose Session</h3>
                    <el-select v-model="selectSession" class="drawer-select">
                        <el-option :label="$t('modal.nq.session.asia')" value="Asia" />
                        <el-option :label="$t('modal.nq.session.eur')" value="EUR" />
                        <el-option :label="$t('modal.nq.session.us')" value="US" />
                    </el-select>
                </el-col>
                <el-col :md="12" class="drawer-col">
                    <h3 class="drawer-title">Choose Mode</h3>
                    <el-select v-model="selectMode" class="drawer-select bottom">
                        <el-option :label="$t('modal.nq.mode.1mink')" value="1T" />
                        <el-option :label="$t('modal.nq.mode.5mink')" value="5T" />
                    </el-select>
                </el-col>
            </el-row>
            <div class="btn gameStartBtn fixedBottomBtn futureStartBtn">
                <a href="javascript:void(0)" title="開始遊戲" @click="onStartGame(`NQ_${selectMode}_${selectSession}`)"> {{
                    $t('gameLobby.freeMode.startGameBtn') }} </a>
            </div>
        </template>
    </common-modal>

    <apply-loan-modal :toggle="applyLoanToggle" :competition-id="competitionId"></apply-loan-modal>
</template>

<script>
import CommonModal from '../CommonModal.vue';
import applyLoanModal from './applyLoanModal.vue';
import { ref } from 'vue';
import { checkPortfolioRule, checkCompetitionPortfolioRule } from '@/composables';
import { useRoute } from 'vue-router';

export default {
    name: 'futuresModeModal',
    props: {
        futuresToggle: {
            default: false,
            required: false
        },
        cryptoToggle: {
            default: false,
            required: false
        },
        nqToggle: {
            default: false,
            required: false
        }
    },
    components: {
        CommonModal,
        applyLoanModal
    },
    setup() {

        const route = useRoute();
        const competitionId = ref(route.params.competition_id);

        const applyLoanToggle = ref(false);

        const selectSession = ref('Asia');
        const selectMode = ref('1T');

        const futuresMode = ref(["1分 K", "5分 K", "15分 K", "小時 K", "日 K"]);
        const futuresGameMode = ref("TX_1T");

        const setFuturesGameMode = (val) => {
            if (val == 0) {
                futuresGameMode.value = 'TX_1T'
            } else if (val == 1) {
                futuresGameMode.value = 'TX_5T'
            } else if (val == 2) {
                futuresGameMode.value = 'TX_15T'
            } else if (val == 3) {
                futuresGameMode.value = 'TX_1H'
            } else if (val == 4) {
                futuresGameMode.value = 'TX_1D'
            }
        }

        const cryptoMode = ref(["1分 K", "5分 K"]);
        const cryptoGameMode = ref("Crypto_1T");

        const setCryptoGameMode = (val) => {
            if (val == 0) {
                cryptoGameMode.value = 'Crypto_1T'
            } else if (val == 1) {
                cryptoGameMode.value = 'Crypto_5T'
            }
        }

        const tit = ref('選擇模式');

        const onStartGame = (game_mode) => {

            if (competitionId.value) {

                checkCompetitionPortfolioRule(
                    applyLoanToggle,
                    game_mode,
                    competitionId.value
                )

            } else {
                applyLoanToggle.value = checkPortfolioRule(
                    applyLoanToggle.value,
                    game_mode
                )
            }
        }

        return {
            applyLoanToggle,
            selectSession,
            selectMode,
            futuresMode,
            futuresGameMode,
            setFuturesGameMode,
            cryptoMode,
            cryptoGameMode,
            setCryptoGameMode,
            onStartGame,
            tit,
            competitionId
        }
    },
    watch: {
        futuresToggle: function () {
            this.futuresGameMode = 'TX_1T';
            this.toggleModal('selectFuturesMode');
        },
        cryptoToggle: function () {
            this.cryptoGameMode = 'Crypto_1T';
            this.toggleModal('selectCryptoMode');
        },
        nqToggle: function () {
            this.toggleModal('selectNqMode');
        },
    },
    methods: {
        toggleModal: function (t) {
            if (window.$cookies.get("localeSetting") == "en") {

                this.futuresMode = ["1Min K", "5Min K", "15Min K", "1H K", "1D K"];
                this.cryptoMode = ["1Min K", "5Min K"];
                this.tit = 'Choose Mode';

            } else {

                this.futuresMode = ["1分 K", "5分 K", "15分 K", "小時 K", "日 K"];
                this.cryptoMode = ["1分 K", "5分 K"];
                this.tit = '選擇模式'

            }
            this.$refs[t].toggle = !this.$refs[t].toggle;
        },
    }
}

</script>

<style>
.drawer-title {
    margin-bottom: 10px;
}

.drawer-select {
    font-size: 16px !important;
    width: 100%;
    margin-bottom: 30px;
}

.drawer-select .el-input__wrapper {
    padding-left: 0px !important;
    background: transparent !important;
    border: solid 1px #bf974a !important;
}

.drawer-select .el-input__inner {
    color: #f9c96d !important;
    font-size: 16px;
}


.el-carousel__item {
    background: #c5a46e !important;
}

.el-carousel__item--card.is-active {
    box-shadow: inset 0 0 25px #3d2c00;
    border-radius: 5px;
}

.el-carousel__item--card.is-in-stage {
    border-radius: 5px;
}

.el-carousel__item h3 {
    font-size: 64px;
    font-style: italic;
    color: black;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
    text-align: center;
}

@media screen and (max-width: 475px) {
    .el-carousel__item h3 {
        font-size: 36px !important;
    }
}

.futureStartBtn {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 35px;
}

@media screen and (max-width: 767px) {

    .drawer-title {
        margin-top: 5px;
    }

    .drawer-select {
        margin-bottom: 10px;
    }

    .drawer-select.bottom {
        margin-bottom: 30px;
    }

    .drawer-col {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .nq-mode-area .modal-container-infoArea {
        padding: 20px!important;
    }
}
</style>