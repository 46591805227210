export const getOHLCData = (stockPrice, upColor, downColor, mode = 'stock') => {

    const ohlc = [];
    const volume = [];

    stockPrice.forEach(function (item) {

        ohlc.push([
            item[0],
            item[1],
            item[2],
            item[3],
            item[4]
        ]);

        let vColor;
        if (item[1] >= item[4]) {
            vColor = downColor.value;
        } else if (item[1] < item[4]) {
            vColor = upColor.value
        }

        let y = item[5];
        if (mode == 'stock') {
            y = Math.round(y / 1000);
        } else {
            y = Math.round(y);
        }

        volume.push({
            x: item[0],
            y: y,
            color: vColor
        })

    });

    return {
        ohlc,
        volume
    }

}

// 日K轉週K
const getWeekStartDate = (timestamp) => {
    const startOfWeek = new Date(timestamp);
    startOfWeek.setHours(0, 0, 0, 0);
    const day = startOfWeek.getDay();
    const diff = startOfWeek.getDate() - day + (day === 0 ? -6 : 1); // Adjust to Monday if Sunday
    startOfWeek.setDate(diff);
    return startOfWeek.getTime();
}

function isNewWeek(date1, date2) {

    const d1 = new Date(date1);
    const d2 = new Date(date2);

    const oneWeek = 7 * 24 * 60 * 60 * 1000; // One week in milliseconds
    const timeDifference = d2.getTime() - d1.getTime();
    return timeDifference >= oneWeek;
}

export const groupDailytoWeekly = (dailyData) => {

    const groups = dailyData.reduce((groupedData, currentDayData) => {

        const currentWeeklyData = groupedData[groupedData.length - 1];

        if (!currentWeeklyData || isNewWeek(currentWeeklyData[0], currentDayData[0])) {

            // Start a new hour interval
            groupedData.push([
                getWeekStartDate(currentDayData[0]),
                currentDayData[1],
                currentDayData[2],
                currentDayData[3],
                currentDayData[4],
                currentDayData[5],
            ]);

        } else {

            currentWeeklyData[2] = Math.max(currentWeeklyData[2], currentDayData[2]);
            currentWeeklyData[3] = Math.min(currentWeeklyData[3], currentDayData[3]);
            currentWeeklyData[4] = currentDayData[4];
            currentWeeklyData[5] += currentDayData[5];

        }

        return groupedData;

    }, []);

    return groups;

}

export const updateDailytoWeekly = async (multiChart, newDailyData, upColor, downColor, mode = 'stock') => {

    // last ohlc data
    let ohlcData = multiChart.series[0].options.data;
    const lastOHLCIndex = ohlcData.length - 1;
    let lastOHLCData = ohlcData[lastOHLCIndex];

    let y = Math.round(newDailyData[5])
    if (mode == 'stock') {
        y = Math.round(newDailyData[5] / 1000);
    }

    if (isNewWeek(lastOHLCData[0], newDailyData[0])) {

        let update_color;
        if (newDailyData[1] >= newDailyData[4]) {
            update_color = downColor.value
        } else {
            update_color = upColor.value
        }

        newDailyData[0] = getWeekStartDate(newDailyData[0])

        multiChart.series[0].addPoint(newDailyData.slice(0, 5));
        multiChart.series[1].addPoint({
            x: newDailyData[0],
            y: y,
            color: update_color
        });

    } else {

        let update_color;
        if (lastOHLCData[1] >= newDailyData[4]) {
            update_color = downColor.value
        } else {
            update_color = upColor.value
        }

        ohlcData[lastOHLCIndex] = [
            lastOHLCData[0],
            lastOHLCData[1],
            Math.max(lastOHLCData[2], newDailyData[2]),
            Math.min(lastOHLCData[3], newDailyData[3]),
            newDailyData[4]
        ];

        await multiChart.series[0].setData(ohlcData);

        // update volume
        let volumeData = multiChart.series[1].options.data;
        const lastVolumeIndex = volumeData.length - 1;
        let lastVolumeData = volumeData[lastVolumeIndex];

        volumeData[lastVolumeIndex] = {
            x: lastVolumeData.x,
            y: lastVolumeData.y + y,
            color: update_color
        }

        await multiChart.series[1].setData(volumeData);
    }
}

export const getMonthNumber = (timestamp) => {
    const date = new Date(timestamp);
    const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
    return `${startDate.getFullYear()}-${startDate.getMonth()}`;
}

const isDifferentMonth = (timestamp1, timestamp2) => {
    const date1 = new Date(timestamp1);
    const date2 = new Date(timestamp2);
    return date1.getMonth() !== date2.getMonth() || date1.getFullYear() !== date2.getFullYear();
}

const getFirstWorkingDayOfMonth = (timestamp) => {

    const date = new Date(timestamp);

    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    let dayOfWeek = firstDayOfMonth.getDay();

    while (dayOfWeek === 0 || dayOfWeek === 6) {
        // If the day is a Sunday (0) or Saturday (6), move to the next day
        firstDayOfMonth.setDate(firstDayOfMonth.getDate() + 1);
        dayOfWeek = firstDayOfMonth.getDay();
    }

    return firstDayOfMonth.getTime();
}

// 日K轉月K
export const groupDailytoMonthly = (dailyData) => {

    const groups = dailyData.reduce((groupedData, currentDayData) => {

        const currentMonthlyData = groupedData[groupedData.length - 1];

        if (!currentMonthlyData || isDifferentMonth(currentMonthlyData[0], currentDayData[0])) {

            // Start a new hour interval
            groupedData.push([
                getFirstWorkingDayOfMonth(currentDayData[0]),
                currentDayData[1],
                currentDayData[2],
                currentDayData[3],
                currentDayData[4],
                currentDayData[5],
            ]);

        } else {

            currentMonthlyData[2] = Math.max(currentMonthlyData[2], currentDayData[2]);
            currentMonthlyData[3] = Math.min(currentMonthlyData[3], currentDayData[3]);
            currentMonthlyData[4] = currentDayData[4];
            currentMonthlyData[5] += currentDayData[5];

        }

        return groupedData;

    }, []);

    return groups;

}

export const updateDailytoMonthly = async (multiChart, newDailyData, upColor, downColor, mode = 'stock') => {

    // last ohlc data
    let ohlcData = multiChart.series[0].options.data;
    const lastOHLCIndex = ohlcData.length - 1;
    let lastOHLCData = ohlcData[lastOHLCIndex];

    let y = Math.round(newDailyData[5])
    if (mode == 'stock') {
        y = Math.round(newDailyData[5] / 1000);
    }

    if (isDifferentMonth(lastOHLCData[0], newDailyData[0])) {

        let update_color;
        if (newDailyData[1] >= newDailyData[4]) {
            update_color = downColor.value
        } else {
            update_color = upColor.value
        }

        newDailyData[0] = getFirstWorkingDayOfMonth(newDailyData[0])

        multiChart.series[0].addPoint(newDailyData.slice(0, 5));
        multiChart.series[1].addPoint({
            x: newDailyData[0],
            y: y,
            color: update_color
        });

    } else {

        let update_color;
        if (lastOHLCData[1] >= newDailyData[4]) {
            update_color = downColor.value
        } else {
            update_color = upColor.value
        }

        ohlcData[lastOHLCIndex] = [
            lastOHLCData[0],
            lastOHLCData[1],
            Math.max(lastOHLCData[2], newDailyData[2]),
            Math.min(lastOHLCData[3], newDailyData[3]),
            newDailyData[4]
        ];

        await multiChart.series[0].setData(ohlcData);

        // update volume
        let volumeData = multiChart.series[1].options.data;
        const lastVolumeIndex = volumeData.length - 1;
        let lastVolumeData = volumeData[lastVolumeIndex];

        volumeData[lastVolumeIndex] = {
            x: lastVolumeData.x,
            y: lastVolumeData.y + y,
            color: update_color
        }

        await multiChart.series[1].setData(volumeData);
    }

}

// 小時K轉日K
export const groupHourtoDaily = (hourData) => {

    const groups = hourData.reduce((groupedData, currentHourData) => {

        const currentHourTime = new Date(currentHourData[0]);
        const currentGroupData = groupedData[groupedData.length - 1];

        if (!currentGroupData || new Date(currentGroupData[0]).getDate() !== currentHourTime.getDate()) {

            // Start a new hour interval
            groupedData.push([
                currentHourData[0],
                currentHourData[1],
                currentHourData[2],
                currentHourData[3],
                currentHourData[4],
                currentHourData[5],
            ]);

        } else {

            currentGroupData[2] = Math.max(currentGroupData[2], currentHourData[2]);
            currentGroupData[3] = Math.min(currentGroupData[3], currentHourData[3]);
            currentGroupData[4] = currentHourData[4];
            currentGroupData[5] += currentHourData[5];

        }

        return groupedData;

    }, []);

    console.log(groups);

    return groups;

}

export const updateHourtoDaily = async (multiChart, newHourData, upColor, downColor, mode = 'stock') => {

    // last ohlc data
    let ohlcData = multiChart.series[0].options.data;
    const lastOHLCIndex = ohlcData.length - 1;
    let lastOHLCData = ohlcData[lastOHLCIndex];

    const lastHourTimestamp = new Date(lastOHLCData[0]);
    const newHourTimestamp = new Date(newHourData[0]);

    let y = Math.round(newHourData[5])
    if (mode == 'stock') {
        y = Math.round(newHourData[5] / 1000);
    }

    if (lastHourTimestamp.getDate() !== newHourTimestamp.getDate()) {

        let update_color;
        if (newHourData[1] >= newHourData[4]) {
            update_color = downColor.value
        } else {
            update_color = upColor.value
        }

        multiChart.series[0].addPoint(newHourData.slice(0, 5));
        multiChart.series[1].addPoint({
            x: newHourData[0],
            y: y,
            color: update_color
        });

    } else {

        let update_color;
        if (lastOHLCData[1] >= newHourData[4]) {
            update_color = downColor.value
        } else {
            update_color = upColor.value
        }

        ohlcData[lastOHLCIndex] = [
            lastOHLCData[0],
            lastOHLCData[1],
            Math.max(lastOHLCData[2], newHourData[2]),
            Math.min(lastOHLCData[3], newHourData[3]),
            newHourData[4]
        ];

        await multiChart.series[0].setData(ohlcData);

        // update volume
        let volumeData = multiChart.series[1].options.data;
        const lastVolumeIndex = volumeData.length - 1;
        let lastVolumeData = volumeData[lastVolumeIndex];

        volumeData[lastVolumeIndex] = {
            x: lastVolumeData.x,
            y: lastVolumeData.y + y,
            color: update_color
        }

        await multiChart.series[1].setData(volumeData);

    }

}

// 分K轉小時K
export const groupMinutetoHour = (minuteData) => {

    const groups = minuteData.reduce((groupedData, currentMinData) => {

        const currentMinTime = new Date(currentMinData[0]);
        const currentGroupData = groupedData[groupedData.length - 1];

        if (!currentGroupData || currentMinTime.getHours() !== new Date(currentGroupData[0]).getHours()) {

            // Start a new hour interval
            groupedData.push([
                currentMinData[0],
                currentMinData[1],
                currentMinData[2],
                currentMinData[3],
                currentMinData[4],
                currentMinData[5],
            ]);

        } else {

            currentGroupData[2] = Math.max(currentGroupData[2], currentMinData[2]);
            currentGroupData[3] = Math.min(currentGroupData[3], currentMinData[3]);
            currentGroupData[4] = currentMinData[4];
            currentGroupData[5] += currentMinData[5];

        }

        return groupedData;

    }, []);

    return groups;

}

export const updateMinutetoHour = async (multiChart, newMinuteData, upColor, downColor, mode = 'stock') => {

    // last ohlc data
    let ohlcData = multiChart.series[0].options.data;
    const lastOHLCIndex = ohlcData.length - 1;
    let lastOHLCData = ohlcData[lastOHLCIndex];

    const lastHourTimestamp = new Date(lastOHLCData[0]);
    const newMinuteTimestamp = new Date(newMinuteData[0]);

    let y = Math.round(newMinuteData[5])
    if (mode == 'stock') {
        y = Math.round(newMinuteData[5] / 1000);
    }

    if (newMinuteTimestamp.getHours() === lastHourTimestamp.getHours()) {

        let update_color;
        if (lastOHLCData[1] >= newMinuteData[4]) {
            update_color = downColor.value
        } else {
            update_color = upColor.value
        }

        ohlcData[lastOHLCIndex] = [
            lastOHLCData[0],
            lastOHLCData[1],
            Math.max(lastOHLCData[2], newMinuteData[2]),
            Math.min(lastOHLCData[3], newMinuteData[3]),
            newMinuteData[4]
        ];

        await multiChart.series[0].setData(ohlcData);

        // update volume
        let volumeData = multiChart.series[1].options.data;
        const lastVolumeIndex = volumeData.length - 1;
        let lastVolumeData = volumeData[lastVolumeIndex];

        volumeData[lastVolumeIndex] = {
            x: lastVolumeData.x,
            y: lastVolumeData.y + y,
            color: update_color
        }

        await multiChart.series[1].setData(volumeData);

    } else {

        let update_color;
        if (newMinuteData[1] >= newMinuteData[4]) {
            update_color = downColor.value
        } else {
            update_color = upColor.value
        }

        multiChart.series[0].addPoint(newMinuteData.slice(0, 5));
        multiChart.series[1].addPoint({
            x: newMinuteData[0],
            y: y,
            color: update_color
        });

    }

}

// 分K轉分K
export const groupMinutetoMinute = (minuteData, minNum) => {

    const groups = minuteData.reduce((groupedData, currentMinData) => {

        const currentMinTime = new Date(currentMinData[0]);
        const currentGroupData = groupedData[groupedData.length - 1];

        if (!currentGroupData || currentMinTime.getMinutes() % minNum === 0) {

            // Start a new hour interval
            groupedData.push([
                currentMinData[0],
                currentMinData[1],
                currentMinData[2],
                currentMinData[3],
                currentMinData[4],
                currentMinData[5],
            ]);

        } else {

            currentGroupData[2] = Math.max(currentGroupData[2], currentMinData[2]);
            currentGroupData[3] = Math.min(currentGroupData[3], currentMinData[3]);
            currentGroupData[4] = currentMinData[4];
            currentGroupData[5] += currentMinData[5];

        }

        return groupedData;

    }, []);

    return groups

}

export const updateMinutetoMinute = async (multiChart, newMinuteData, upColor, downColor, minNum, mode = 'stock') => {

    // last ohlc data
    let ohlcData = multiChart.series[0].options.data;
    const lastOHLCIndex = ohlcData.length - 1;
    let lastOHLCData = ohlcData[lastOHLCIndex];

    const newMinuteTimestamp = new Date(newMinuteData[0]);

    let y = Math.round(newMinuteData[5])
    if (mode == 'stock') {
        y = Math.round(newMinuteData[5] / 1000);
    }

    if (newMinuteTimestamp.getMinutes() % minNum === 0) {

        let update_color;
        if (newMinuteData[1] >= newMinuteData[4]) {
            update_color = downColor.value
        } else {
            update_color = upColor.value
        }

        await multiChart.series[0].addPoint(newMinuteData.slice(0, 5));
        await multiChart.series[1].addPoint({
            x: newMinuteData[0],
            y: y,
            color: update_color
        });

    } else {

        let update_color;
        if (lastOHLCData[1] >= newMinuteData[4]) {
            update_color = downColor.value
        } else {
            update_color = upColor.value
        }

        ohlcData[lastOHLCIndex] = [
            lastOHLCData[0],
            lastOHLCData[1],
            Math.max(lastOHLCData[2], newMinuteData[2]),
            Math.min(lastOHLCData[3], newMinuteData[3]),
            newMinuteData[4]
        ];

        await multiChart.series[0].setData(ohlcData);

        // update volume
        let volumeData = multiChart.series[1].options.data;
        const lastVolumeIndex = volumeData.length - 1;
        let lastVolumeData = volumeData[lastVolumeIndex];

        volumeData[lastVolumeIndex] = {
            x: lastVolumeData.x,
            y: lastVolumeData.y + y,
            color: update_color
        }

        await multiChart.series[1].setData(volumeData);

    }

}