<template>
    <common-modal ref="bankrupt" :can-close="true" tit="" class="md">
        <template v-slot:infoArea>
            <h2 class="text-center" style="color: rgb(155,24,51);">{{ $t('modal.applyLoan.desc.1') }}</h2>
            <h4 class="text-center">{{ $t('modal.applyLoan.desc.2') }}</h4>
            <h4 class="text-center">{{ $t('modal.applyLoan.desc.3') }}</h4>
            <el-button id="end-btn" type="coffee" class="blockBtn margin-bottom-30" @click="applyLoan(competitionId)" v-if="competitionId">{{ $t('modal.applyLoan.applyBtn') }}</el-button>
            <el-button id="end-btn" type="coffee" class="blockBtn margin-bottom-30" @click="this.$router.push({name: 'bank'})" v-else>{{ $t('modal.applyLoan.goToBtn') }}</el-button>
        </template>
    </common-modal>
</template>

<script>
import CommonModal from '../CommonModal.vue';
import { applyLoan } from '@/composables'

export default {
    name: 'gameOverModal',
    props: {
        toggle: {
            default: false,
            required: true
        },
        competitionId: {
            default: null,
            required: false
        }
    },
    setup() {
        return {
            applyLoan
        }
    },
    components: {
        CommonModal
    },
    watch: {
        toggle: function () {
            this.toggleModal('bankrupt');
        }
    },
    methods: {
        toggleModal: function (t) {
            this.$refs[t].toggle = !this.$refs[t].toggle;
        },
    }
}

</script>

<style scoped>
#end-btn {
    font-size: 16px;
    height: 40px;
}
</style>