import axios from "axios";
import { useCommonStore, useGamerStore, useHistoryStore } from "@/store";
import { apiData } from "./useCommon";
import { ElMessage } from "element-plus";

export const getGamerInfo = async () => {

    const commonStore = useCommonStore();
    const gamerStore = useGamerStore();
    const { apiBaseurl } = apiData();
    const gamer_id = gamerStore.user_id;

    axios({
        method: "get",
        url: apiBaseurl + "/gamer/info",
        params: {
            gamer_id: gamer_id,
            mode: "detail",
            lang: commonStore.localeSetting
        }
    }).then(response => {
        const result = response.data.Result;
        console.log(result);

        // 基本資料
        const user_data = result.user_data;
        gamerStore.user_id = user_data.user_id;
        gamerStore.username = user_data.username;
        gamerStore.rank = user_data.rank;
        gamerStore.asset_level = user_data.asset_level;
        gamerStore.total_asset = user_data.total_asset;
        gamerStore.convert_asset = user_data.convert_asset;
        gamerStore.profile_picture = user_data.profile_picture;
        gamerStore.level = user_data.level;
        gamerStore.self_content = user_data.self_content;

        // 戰績相關資料
        gamerStore.score_board.avg_return = user_data.avg_return;
        gamerStore.score_board.game_count = user_data.game_count;
        gamerStore.score_board.last_10 = user_data.last_10;
        gamerStore.score_board.win_count = user_data.win_count;
        gamerStore.score_board.win_rate = user_data.win_rate;

        // 社群連結
        gamerStore.social_link.facebook = user_data.user_social_link.facebook;
        gamerStore.social_link.instagram = user_data.user_social_link.instagram;
        gamerStore.social_link.line = user_data.user_social_link.line;

        // 獎章
        gamerStore.awards.data = user_data.awards;

        // 文章資料
        const article_data = result.article_data;
        gamerStore.article_data.Latest_Post = article_data.Latest_Post;
        gamerStore.article_data.Reply = article_data.Reply;
    })

}

export const getGamerList = async (gamer_id_list) => {

    const { userToken, apiBaseurl } = apiData();

    const result = axios({
        method: 'post',
        url: apiBaseurl + '/gamer/list',
        headers: {
            Authorization: "Bearer "+ userToken
        },
        data: {
            gamer_id_list: gamer_id_list
        }
    })
    .then(response => {
        return response.data.Result;
    });

    return result;
}

export const gamerHistoryRecords = async (page) => {

    const historyStore = useHistoryStore();
    const { userToken, apiBaseurl } = apiData();

    const gamerStore = useGamerStore();
    const gamer_id = gamerStore.user_id;

    await axios({
        method: "post",
        url: apiBaseurl + '/gamer/game_history',
        headers: {
            Authorization: "Bearer "+ userToken
        },
        data: {
            gamer_id: gamer_id,
            page: page,
            limit: historyStore.pageQty,
            sort_col: historyStore.sort_col,
            sort_type: historyStore.sort_type,
            search_name: historyStore.search_name
        }
    }).then(response => {
        var result = response.data.Result;
        historyStore.history_records = result.items;
        historyStore.total_items = result.total_items;
        document.getElementById("records-top").scrollIntoView();
        
    }).catch(error => {
        var err = error.response.data;
        ElMessage({
            showClose: true,
            type: "error",
            message: err.Status.Desc,
            duration: 1500
        })
    })

}

export const gamerHistoryAssets = async (limit) => {

    const historyStore = useHistoryStore();
    const { userToken, apiBaseurl } = apiData();

    const gamerStore = useGamerStore();
    const gamer_id = gamerStore.user_id;

    let num;
    if (limit == "all") {
        num = null
    } else {
        num = parseInt(limit)
    }

    await axios({
        method: "get",
        url: apiBaseurl + '/gamer/asset_history',
        headers: {
            Authorization: "Bearer "+ userToken
        },
        params: {
            gamer_id: gamer_id,
            limit: num
        }
    }).then(response => {
        var result = response.data.Result;
        historyStore.assetsChartOptions.series[0].data = result;
    })
}

export const gamerHistoryReturn = async (limit) => {

    const historyStore = useHistoryStore();
    const { userToken, apiBaseurl } = apiData();

    const gamerStore = useGamerStore();
    const gamer_id = gamerStore.user_id;

    let num;
    if (limit == "all") {
        num = null
    } else {
        num = parseInt(limit)
    }

    await axios({
        method: "get",
        url: apiBaseurl + '/gamer/return_history',
        headers: {
            Authorization: "Bearer "+ userToken
        },
        params: {
            gamer_id: gamer_id,
            limit: num
        }
    }).then(response => {
        var result = response.data.Result;
        historyStore.returnChartOptions.series[0].data = result;
    })

}