import axios from "axios";
import { apiData } from "./useCommon";
import { useCommonStore, useFriendStore, useSubscribeStore } from "@/store";
import { ElMessage, ElMessageBox } from "element-plus";

export const getSubList = async () => {

    const store = useSubscribeStore();
    const { userToken, apiBaseurl } = apiData();

    await axios({
        method: 'get',
        url: apiBaseurl + '/subscribe/list',
        headers: {
            Authorization: "Bearer " + userToken
        }
    }).then(response => {
        const result = response.data.Result;

        store.followers = result.followers;
        store.following = result.following;

    }).catch(error => {
        const error_msg = error.response.data;

        ElMessage({
            showClose: true,
            type: "error",
            message: error_msg.Status.Desc,
            duration: 1500
        });

    })
}

export const addSub = async (subId) => {

    const store = useFriendStore();
    const { userToken, apiBaseurl } = apiData();

    await axios({
        method: 'post',
        url: apiBaseurl + '/subscribe/add',
        headers: {
            Authorization: "Bearer " + userToken
        },
        data: {
            subscribe_id: subId
        }
    }).then(response => {
        store.isSub = true;

        const awards_list = response.data.Result.awards_list;
        console.log(awards_list);
        if (awards_list.length > 0) {
            const commonStore = useCommonStore();
            commonStore.awards.data = awards_list;
            commonStore.awards.toggle = !commonStore.awards.toggle;
        }

    }).catch(error => {
        const error_msg = error.response.data;
        ElMessage({
            showClose: true,
            message: error_msg.Status.Desc,
            type: 'error',
            duration: 1500
        });
    })
}

export const removeSub = async (subId) => {

    await ElMessageBox.confirm(
        '您將取消訂閱，是否繼續？',
        '取消訂閱',
        {
            confirmButtonText: '確認',
            cancelButtonText: '取消',
            confirmButtonClass: 'el-button--coffee',
            type: 'warning'
        }
    ).then(async () => {

        const subStore = useSubscribeStore();
        const friendStore = useFriendStore();
        const { userToken, apiBaseurl } = apiData();

        await axios({
            method: 'post',
            url: apiBaseurl + '/subscribe/remove',
            headers: {
                Authorization: "Bearer " + userToken
            },
            data: {
                subscribe_id: subId
            }
        }).then(() => {

            subStore.following = subStore.following.filter(item => item.user_id != subId);
            friendStore.isSub = false;

        }).catch(error => {
            const error_msg = error.response.data;

            ElMessage({
                showClose: true,
                message: error_msg.Status.Desc,
                type: 'error'
            });
        });

    })

}