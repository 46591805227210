<template>

    <div></div>

    <section class="item margin-top-10 margin-bottom-0"
        :style="windowWidth <= 475 ? 'margin-top: 10px; margin-bottom: 0px;' : ''" v-if="localeSetting == 'zh-TW'">

        <div class="itemBlock" v-if="windowWidth > 475">
            <a href="/referral" title="廣告">
                <img src="../assets/images/ads/Share_zh_1087x280.png" alt="" class="adBanner">
            </a>
        </div>

        <div class="itemBlock" v-else>
            <a href="/referral" title="廣告">
                <img src="../assets/images/ads/Share_zh_500x500.gif" alt="" class="adBanner" style="border-radius: 5px;">
            </a>
        </div>

    </section>

    <section class="item margin-top-10 margin-bottom-0"
        :style="windowWidth <= 475 ? 'margin-top: 10px; margin-bottom: 0px;' : ''" v-else-if="localeSetting == 'en'">

        <div class="itemBlock" v-if="windowWidth > 475">
            <a href="/referral">
                <img src="../assets/images/ads/Share_en_1087x280.png" alt="" class="adBanner">
            </a>
        </div>

        <div class="itemBlock" v-else>
            <a href="/referral">
                <img src="../assets/images/ads/Share_en_500x500.gif" alt="" class="adBanner" style="border-radius: 5px;">
            </a>
        </div>

    </section>

</template>

<script>
import { useCommonStore } from '@/store';
import { computed, onMounted, ref } from "vue";

export default {
    name: 'headerAds',
    setup() {

        const commonStore = useCommonStore();
        const localeSetting = computed(() => commonStore.localeSetting);
        const windowWidth = computed(() => commonStore.windowWidth);

        const getRandomInt = (max) => {
            return Math.floor(Math.random() * max);
        }

        const adNum = ref(null);

        onMounted(() => {
            adNum.value = getRandomInt(2);
        });

        return {
            adNum,
            localeSetting,
            windowWidth
        }

    }
}

</script>