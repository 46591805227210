import { useCommonStore, useUserStore } from "@/store";
import axios from "axios";
import { ElLoading, ElMessage } from "element-plus";
import { googleTokenLogin } from "vue3-google-login";
import { apiData } from "./useCommon";
import { getUserBasic } from "./getUserInfo";

export const accountConnect = async (address) => {

    const { apiBaseurl } = apiData();
    const userStore = useUserStore();

    const connect_result = await axios({
        method: "get",
        url: apiBaseurl + '/auth/web3/connect_status',
        params: {
            address: address
        }
    }).then(response => {
        userStore.isWalletConnected = true;
        return response.data.Result;
    }).catch(error => {

        const errorData = error.response.data;

        ElMessage({
            showClose: true,
            message: errorData.Status.Desc,
            type: 'error',
            duration: 1500
        });

        return;
    });

    if (connect_result.isLogin) {

        await window.$cookies.set("userToken", connect_result["access_token"], "1d", "/", null, true, "None");
        await getUserBasic();
        userStore.isLogin = true;
        
    } else {
        await window.$cookies.remove("userToken");
        userStore.isLogin = false;
    }

}

export const generalConnect = async (address, email, password) => {

    const { apiBaseurl } = apiData();

    const connectResult = await axios({
        method: 'post',
        url: apiBaseurl + '/auth/web3/connect_old_account',
        data: {
            address: address,
            mode: 'normal',
            email: email,
            password: password
        }
    }).then(response => {

        return response.data.Result;

    }).catch(error => {
        const error_data = error.response.data;
        ElMessage({
            showClose: true,
            message: error_data.Status.Desc,
            type: 'error',
            duration: 1500
        });
        return;
    });

    if (connectResult.isLogin) {
        await window.$cookies.set("userToken", connectResult["access_token"], "1d", "/");
        window.location.reload();
    }

}

export const googleConnect = async (address) => {

    googleTokenLogin().then(async (response) => {

        const access_token = response.access_token;

        const { apiBaseurl } = apiData();

        const connectResult = await axios({
            method: 'post',
            url: apiBaseurl + '/auth/web3/connect_old_account',
            data: {
                address: address,
                mode: 'google',
                id_token: access_token
            }
        }).then(response => {
            return response.data.Result;
        }).catch(error => {
            const error_data = error.response.data;
            ElMessage({
                showClose: true,
                message: error_data.Status.Desc,
                type: 'error',
                duration: 1500
            });
            return;
        });

        if (connectResult.isLogin) {
            await window.$cookies.set("userToken", connectResult["access_token"], "1d", "/");
            window.location.reload();
        }
    })
}

export const discordRedirect = async () => {

    const hostname = window.location.hostname;

    if (window.xnft?.solana?.isXnft) {

        window.open("https://discord.com/api/oauth2/authorize?client_id=1065254339410673677&redirect_uri=https%3A%2F%2Fwww.tdtn.xyz%2Fdiscord%2Fxnft&response_type=code&scope=identify%20email", "_blank");

    } else {

        if (hostname == 'www.tdtn.xyz') {
            window.location.href = 'https://discord.com/api/oauth2/authorize?client_id=1065254339410673677&redirect_uri=https%3A%2F%2Fwww.tdtn.xyz%2Fdiscord&response_type=code&scope=identify%20email'
        } else {
            window.location.href = 'https://discord.com/api/oauth2/authorize?client_id=1065254339410673677&redirect_uri=https%3A%2F%2Fwww.tdtn.tw%2Fdiscord&response_type=code&scope=identify%20email';
        }

        // window.location.href = 'https://discord.com/api/oauth2/authorize?client_id=1065254339410673677&redirect_uri=http%3A%2F%2F127.0.0.1%3A8080%2Fdiscord&response_type=code&scope=identify%20email';

    }
}

export const discordConnect = async (address, code, type = "web") => {

    const { apiBaseurl } = apiData();
    const referral_code = window.$cookies.get("referralCode");

    const connectResult = await axios({
        method: 'post',
        url: apiBaseurl + '/auth/web3/create_new_account',
        data: {
            address: address,
            code: code,
            type: type,
            referral_code: referral_code
        }
    }).then(response => {
        return response.data.Result;
    }).catch(async (error) => {

        console.log(error);
        const error_data = error.response.data;
        ElMessage({
            showClose: true,
            message: error_data.Status.Desc,
            type: 'error',
            duration: 1500
        });

        await new Promise((resolve) => setTimeout(resolve, 1000));
        window.location.href = `/?referral_code=${referral_code}`;

        return;
    });

    if (connectResult.isLogin) {
        if (window.location.pathname == '/discord/xnft') {
            window.location.href = '/discord/complete';
        } else {
            await window.$cookies.set('userToken', connectResult["access_token"], '1d', '/', null, true, "None");
            window.location.href = '/';
        }
    }

}