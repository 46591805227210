<template>
    <zh-header :header-type="headerType" :is-active1="isActive1" :is-active2="isActive2" :is-active3="isActive3"
        v-if="localeSetting == 'zh-TW'"></zh-header>
    <en-header :header-type="headerType" :is-active1="isActive1" :is-active2="isActive2" :is-active3="isActive3"
        v-else></en-header>
</template>

<script>
import zhHeader from './page_header/zhHeader.vue';
import enHeader from './page_header/enHeader.vue';
import { useCommonStore, useUserStore } from '@/store';
import { computed, ref, onMounted } from 'vue';
import { useWorkspace, loginLog } from "@/composables"
import { ElLoading } from "element-plus";
import { useRoute } from 'vue-router';

export default {
    name: 'PageHeader',
    components: {
        zhHeader,
        enHeader
    },
    props: {
        headerType: {
            type: String,
            default: "header type2"
        },
        isActive1: {
            type: Boolean,
            default: false
        },
        isActive2: {
            type: Boolean,
            default: false
        },
        isActive3: {
            type: Boolean,
            default: false
        }
    },
    setup() {

        const route = useRoute();
        const referralCode = route.query.referral_code;

        const commonStore = useCommonStore();
        const userStore = useUserStore();
        const localeSetting = computed(() => commonStore.localeSetting);

        const { wallet } = useWorkspace();
        window.wallet = wallet;

        let loadingSecond = 800;
        if (window.location.pathname == '/game') {
            loadingSecond = 2000;
        }

        onMounted(() => {

            let loading;
            if (window.location.pathname != '/game') {
                loading = ElLoading.service({
                    lock: true,
                    text: 'Loading...',
                    background: 'black'
                });
            }

            setTimeout(async () => {

                // Step1 判定是否有 window.xnft 之 extension
                if (window.xnft) {

                    console.log('有偵測到 xnft extension');

                    // Step 2 延遲一秒執行來判定是否為 xNFT
                    setTimeout(async () => {

                        console.log('isXnft', window.xnft.solana.isXnft)
                        console.log('loadingSecond', loadingSecond)

                        // 若是，則設定 autoConnect 為 0，並將 isXnft 設定為 true，且給予 xNftAddress 值
                        if (window.xnft.solana.isXnft) {

                            await window.$cookies.set('autoConnect', false, '365d', '/', null, true, "None");
                            commonStore.isXnft = true;
                            userStore.isWalletConnected = true;
                            userStore.xNftAddress = window.xnft.solana.publicKey.toBase58();

                            // 若是有選擇錢包之狀態
                            if (wallet.wallet.value) {
                                await wallet.select(null);
                            }

                        } else {

                            await window.$cookies.set('autoConnect', true, '365d', '/', null, true, "None");
                            commonStore.isXnft = false;

                        }
                        if (loading && ['/game_over', '/game_detail', '/competition/game_over'].includes(window.location.pathname) == false) {
                            loading.close();
                        }

                    }, loadingSecond);

                } else {

                    console.log('無偵測到 xnft extension，照正常網頁連接程序');
                    // 設定錢包為自動連接
                    window.$cookies.set('autoConnect', true, '365d', '/', null, true, "None");
                    commonStore.isXnft = false;

                    // 結算頁面會自行 close loading
                    if (loading && ['/game_over', '/game_detail', '/competition/game_over'].includes(window.location.pathname) == false) {
                        loading.close();
                    }

                }

                if (window.$cookies.isKey("userToken")) {

                    await loginLog();

                }
                
                window.$cookies.set('referralCode', referralCode, '1d', '/', null, true, "None");
                
            }, 200);

        });

        return {
            commonStore,
            userStore,
            localeSetting
        }
    },
    created() {

        // 不知為何 composition API 讀不到 i18n，先放來 create() 做
        // 設定 i18n 語言
        if (this.$cookies.isKey('localeSetting')) {

            this.$i18n.locale = window.$cookies.get("localeSetting");
            this.commonStore.localeSetting = window.$cookies.get("localeSetting");

        } else {

            const hostname = window.location.hostname;

            if (hostname == 'www.tdtn.tw') {

                this.$i18n.locale = 'zh-TW';
                this.commonStore.localeSetting = 'zh-TW';
                window.$cookies.set('localeSetting', 'zh-TW', '365d', '/', null, true, "None");

            } else {

                this.$i18n.locale = 'en';
                this.commonStore.localeSetting = 'en';
                window.$cookies.set('localeSetting', 'en', '365d', '/', null, true, "None");

            }
        }

        // 依據 i18n 語言設定，給予網頁 Title
        if (this.$route.meta.title) {
            document.title = this.$t(`routes.${this.$route.meta.title}.title`)
        }
    }
}

</script>