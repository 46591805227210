import axios from "axios";
import { apiData } from "./useCommon";
import { ElMessage } from "element-plus";
import { useCommonStore } from "@/store";

export const submitWish = async (title, content) => {

    const { userToken, apiBaseurl } = apiData();

    if (title == '' || content == '') {
        ElMessage({
            showClose: true,
            message: '送出願望失敗',
            type: "error",
            duration: 1500
        })
        return;
    }

    await axios({
        method: 'post',
        url: apiBaseurl + '/wishing_tree/insert',
        headers: {
            Authorization: "Bearer " + userToken
        },
        data: {
            title: title,
            content: content
        }
    }).then(response => {

        const awards_list = response.data.Result.awards_list;
        if (awards_list.length > 0) {
            const commonStore = useCommonStore();
            commonStore.awards.data = awards_list;
            commonStore.awards.toggle = !commonStore.awards.toggle;
        }

        const localeSetting = window.$cookies.get('localeSetting');
        let msg = '送出願望成功';
        if (localeSetting == 'en') {
            msg = 'Success';
        }

        ElMessage({
            showClose: true,
            message: msg,
            type: 'success',
            duration: 1500
        });

    }).catch(() => {
        ElMessage({
            showClose: true,
            message: '送出願望失敗',
            type: 'error',
            duration: 1500
        })
    })
}

export const applyLoan = async (id = null) => {

    const { userToken, apiBaseurl } = apiData();

    await axios({
        method: 'post',
        url: apiBaseurl + '/bank/apply_loan',
        headers: {
            Authorization: "Bearer " + userToken
        },
        data: {
            competition_id: id
        }
    }).then(() => {

        window.location.reload();

    }).catch(error => {

        var error_msg = error.response.data;

        ElMessage({
            showClose: true,
            message: error_msg.Status.Desc,
            type: 'error',
            duration: 1500
        });

    })
}

export const redeemCode = async (redeemCode) => {

    const { userToken, apiBaseurl } = apiData();

    await axios({
        method: "post",
        url: apiBaseurl + "/permission/redeem",
        data: {
            redeem_code: redeemCode
        },
        headers: {
            Authorization: "Bearer " + userToken
        }
    }).then(response => {
        ElMessage({
            showClose: true,
            type: "success",
            message: response.data.Status.Desc,
            duration: 1500
        });
    }).catch(error => {
        const errorMsg = error.response.data;
        ElMessage({
            showClose: true,
            type: "error",
            message: errorMsg.Status.Desc,
            duration: 1500
        });
    })

}