import {
    Connection,
    SystemProgram,
    Transaction,
    TransactionInstruction,
    clusterApiUrl,
    LAMPORTS_PER_SOL,
    PublicKey
} from "@solana/web3.js";
import { useWallet } from "solana-wallets-vue";
import { useCommonStore, useUserStore } from "@/store";
import { apiData } from "./useCommon";
import axios from "axios";
import { ElLoading, ElMessage } from "element-plus";
import { computed } from "vue";

export const transferSOL = async (transferAmount) => {

    const userStore = useUserStore();
    const commonStore = useCommonStore();

    const isXnft = commonStore.isXnft;
    const xNftAddress = computed(() => new PublicKey(userStore.xNftAddress));

    const { publicKey, sendTransaction } = useWallet();

    if (userStore.in_game) {
        ElMessage({
            showClose: true,
            type: "error",
            message: "Please End the Game First",
            duration: 1500
        });
        return;
    }

    let myWalletKey;
    if (isXnft) {
        myWalletKey = xNftAddress
    } else {
        myWalletKey = publicKey
    }

    if (!myWalletKey.value) {
        ElMessage({
            showClose: true,
            type: "error",
            message: "Please Connect your Wallet",
            duration: 1500
        });
        return;
    }

    const store = useCommonStore();
    const network = store.solanaNetwork;

    let clusterUrl = clusterApiUrl(network);
    if (network == 'mainnet-beta') {
        clusterUrl = 'https://icy-morning-waterfall.solana-mainnet.quiknode.pro/1d4818ff9d1be4c175581c59ddcff56efd9731e6/';
        // clusterUrl = 'https://api.metaplex.solana.com/';
    }

    const connection = new Connection(clusterUrl);
    const recipientPublicKey = '6kzjSiU8q2Sf7basGp3omoxUNR9NBQx3seDEykxDzATn';

    try {
        // Build the transfer transaction
        const transaction = new Transaction().add(
            SystemProgram.transfer({
                fromPubkey: myWalletKey.value,
                toPubkey: new PublicKey(recipientPublicKey),
                lamports: transferAmount * LAMPORTS_PER_SOL
            })
        );

        const userId = userStore.user_id;

        transaction.add(
            new TransactionInstruction({
                keys: [{ pubkey: myWalletKey.value, isSigner: true, isWritable: true }],
                programId: new PublicKey("MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr"),
                data: Buffer.from("Reset:"+userId.toString(), "utf-8")
            })
        );

        let signature;
        if (isXnft) {
            signature = await window.xnft.solana.sendAndConfirm(transaction);
        } else {
            signature = await sendTransaction(transaction, connection);
        }

        // Sign and send the transaction using the wallet
        // const signature = await sendTransaction(transaction, connection);

        console.log('Transfer successful. Signature:', signature);

        const localeSetting = store.localeSetting;
        let loadingText = '交易中，約需等待30秒，請勿關閉視窗';
        if (localeSetting == 'en') {
            loadingText = 'In Transaction, Wait about 30 seconds, Please do not close the window';
        }
        const loading = ElLoading.service({
            lock: true,
            text: loadingText,
            background: 'rgba(0, 0, 0, 0.8)',
        })

        // Wait for the transaction to be confirmed
        let status = "processed";
        while (status != "finalized") {

            const confirmedTransaction = await connection.getSignatureStatus(signature);
            console.log(confirmedTransaction)
            status = confirmedTransaction.value?.confirmationStatus;

            if (status == 'finalized') {
                break
            } else {
                await new Promise(r => setTimeout(r, 5000));
            }
        }

        const { apiBaseurl, userToken } = apiData();
        await axios({
            method: "post",
            url: apiBaseurl + "/game/reset_asset",
            headers: {
                Authorization: "Bearer "+ userToken
            },
            data: {
                signature: signature
            }
        }).then(() => {
            window.location.reload();
        }).catch(error => {
            const errorMsg = error.response.data;
            ElMessage({
                showClose: true,
                type: "error",
                message: errorMsg.Status.Desc,
                duration: 1500
            });
        });

        loading.close();

    } catch (error) {
        console.error('Transfer failed:', error);
    }

}