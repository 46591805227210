import axios from "axios";
import { apiData } from "./useCommon";
import { useFriendStore, useGamerStore, useUserStore } from "@/store";
import { ElMessage, ElMessageBox } from "element-plus";
import { getNotifyHeader } from "./useNotify";

export const getFriendStatus = async () => {

    const gamerStore = useGamerStore();
    const friendStore = useFriendStore();
    const gamer_id = gamerStore.user_id;

    const { userToken, apiBaseurl } = apiData();

    await axios({
        method: 'post',
        url: apiBaseurl + '/friend/status',
        headers: {
            Authorization: "Bearer " + userToken
        },
        data: {
            friend_id: gamer_id
        }
    }).then(response => {
        var result = response.data;
        friendStore.friend_code = result.Result.code;
        friendStore.isSub = result.Result.isSub;
    }).catch(error => {
        console.log(error);
    });
}

export const getFriendList = async () => {

    const { userToken, apiBaseurl } = apiData();
    const store = useFriendStore();

    await axios({
        method: "get",
        url: apiBaseurl + '/friend/list',
        headers: {
            Authorization: "Bearer " + userToken
        }
    }).then(response => {

        const result = response.data.Result;
        store.friends = result.friends;
        store.blacklist = result.blacklist;
        store.invite = result.invite;

    }).catch(error => {
        const error_msg = error.response.data;
        ElMessage({
            showClose: true,
            type: "error",
            message: error_msg.Status.Desc,
            duration: 1500
        });
    })

}

export const removeFriend = async (friendId) => {

    const store = useFriendStore();

    await ElMessageBox.confirm(
        '您將刪除此好友，是否繼續？',
        '刪除好友',
        {
            confirmButtonText: '確認',
            cancelButtonText: '取消',
            confirmButtonClass: 'el-button--coffee',
            type: 'warning',
        }
    ).then(async () => {

        const { userToken, apiBaseurl } = apiData();

        axios({
            method: 'post',
            url: apiBaseurl + '/friend/remove',
            headers: {
                Authorization: "Bearer " + userToken
            },
            data: {
                friend_id: friendId
            }
        }).then(() => {
            
            store.friends = store.friends.filter(
                item => item.user_id != friendId
            );
            store.friend_code = 5;
            getNotifyHeader();

        }).catch(error => {
            const error_msg = error.response.data;
            ElMessage({
                showClose: true,
                message: error_msg.Status.Desc,
                type: 'error',
                duration: 1500
            });

        })
    })
}

export const addBlackList = async (blackId) => {

    const friendStore = useFriendStore();
    const userStore = useUserStore();

    const my_user_id = userStore.user_id;

    if (my_user_id != blackId) {

        const { userToken, apiBaseurl } = apiData();

        axios({
            method: 'post',
            url: apiBaseurl + '/friend/blacklist/add',
            headers: {
                Authorization: "Bearer " + userToken
            },
            data: {
                friend_id: blackId
            }
        }).then(response => {

            const result = response.data.Result;
            friendStore.blacklist.push(result);

            friendStore.invite = friendStore.invite.filter(item => item.user_id != blackId);
            friendStore.friends = friendStore.friends.filter(item => item.user_id != blackId);

        }).catch(error => {
            const error_msg = error.response.data;
            ElMessage({
                showClose: true,
                type: "error",
                message: error_msg.Status.Desc,
                duration: 1500
            });
        });

    } else {
        ElMessage({
            showClose: true,
            type: "error",
            message: "不能封鎖自己",
            duration: 1500
        });
    }

}

export const removeBlack = async (blackId) => {

    const store = useFriendStore();
    const { userToken, apiBaseurl } = apiData();

    await axios({
        method: 'post',
        url: apiBaseurl + '/friend/blacklist/remove',
        headers: {
            Authorization: "Bearer " + userToken
        },
        data: {
            friend_id: blackId
        }
    }).then(() => {

        store.blacklist = store.blacklist.filter(item => item.user_id != blackId);
        store.friend_code = 5;
        ElMessage({
            showClose: true,
            type: "success",
            message: '已從黑名單移除',
            duration: 1500
        });

    }).catch(error => {

        const error_msg = error.response.data;
        ElMessage({
            showClose: true,
            type: "error",
            message: error_msg.Status.Desc,
            duration: 1500
        });

    });

}

export const sendFriendInvite = async (friendId) => {

    const store = useFriendStore();
    const { userToken, apiBaseurl } = apiData();

    await axios({
        method: 'post',
        url: apiBaseurl + '/friend/invite/send',
        headers: {
            Authorization: "Bearer " + userToken
        },
        data: {
            friend_id: friendId
        }
    }).then(response => {

        const result = response.data.Result;
        if (result.code == 1) {
            store.friend_code = 2;
        } else if (result.code == 2) {
            store.friend_code = 1
        }

    }).catch(error => {

        const error_msg = error.response.data;

        ElMessage({
            showClose: true,
            message: error_msg.Status.Desc,
            type: 'error',
            duration: 1500
        });

    })

}

export const removeInvite = async (gamerId) => {

    const store = useFriendStore();
    const { userToken, apiBaseurl } = apiData();

    await axios({
        method: 'post',
        url: apiBaseurl + '/friend/invite/remove',
        headers: {
            Authorization: "Bearer " + userToken
        },
        data: {
            friend_id: gamerId
        }
    }).then(() => {
        store.friend_code = 5;
    }).catch(error => {
        const error_msg = error.response.data;

        if (error_msg.Status.Desc == '無此好友邀請') {
            window.location.reload();
        } else {
            ElMessage({
                showClose: true,
                message: error_msg.Status.Desc,
                type: 'error',
                duration: 1500
            });
        }

    })

}