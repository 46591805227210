import {
    PublicKey,
    SystemProgram,
    TransactionInstruction,
    SYSVAR_SLOT_HASHES_PUBKEY,
    SYSVAR_INSTRUCTIONS_PUBKEY,
    ComputeBudgetProgram,
} from "@solana/web3.js";
import { BN } from "bn.js";
import {
    CandyMachine,
    PROGRAM_ID,
    AccountVersion
} from "@metaplex-foundation/mpl-candy-machine-core";

import {
    createMintV2Instruction,
    createRouteInstruction,
    GuardType,
    PROGRAM_ID as CANDY_GUARD_PROGRAM_ID
} from "@metaplex-foundation/mpl-candy-guard";

import {
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID
} from "@solana/spl-token";

import {
    getMerkleProof,
    getMerkleRoot,
} from "@metaplex-foundation/js";

import { u32 } from "@metaplex-foundation/beet"

export const CANDY_MACHINE_PROGRAM = PROGRAM_ID
export const METAPLEX_PROGRAM_ID = new PublicKey(
    "metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s"
)

export async function mintV2Instruction(
    candyGuard,
    candyMachine,
    minter,
    payer,
    mint,
    connection,
    metaplex,
    remainingAccounts,
    mintArgs,
    label
) {
    const candyMachineObject = await CandyMachine.fromAccountAddress(
        connection,
        candyMachine
    );

    const nftMetadata = metaplex.nfts().pdas().metadata({ mint: mint.publicKey })
    const nftMasterEdition = metaplex
        .nfts()
        .pdas()
        .masterEdition({ mint: mint.publicKey })

    const nftTokenAccount = metaplex
        .tokens()
        .pdas()
        .associatedTokenAccount({ mint: mint.publicKey, owner: minter.value })

    const authorityPda = metaplex
        .candyMachines()
        .pdas()
        .authority({ candyMachine })

    const collectionMint = candyMachineObject.collectionMint
    // retrieves the collection nft
    const collection = await metaplex
        .nfts()
        .findByMint({ mintAddress: collectionMint })
    // collection PDAs
    const collectionMetadata = metaplex
        .nfts()
        .pdas()
        .metadata({ mint: collectionMint })
    const collectionMasterEdition = metaplex
        .nfts()
        .pdas()
        .masterEdition({ mint: collectionMint })

    const collectionDelegateRecord = metaplex
        .nfts()
        .pdas()
        .metadataDelegateRecord({
            mint: collectionMint,
            type: "CollectionV1",
            updateAuthority: collection.updateAuthorityAddress,
            delegate: authorityPda,
        })


    const accounts = {
        candyGuard,
        candyMachineProgram: CANDY_MACHINE_PROGRAM,
        candyMachine,
        payer: payer,
        minter: minter.value,
        candyMachineAuthorityPda: authorityPda,
        nftMasterEdition: nftMasterEdition,
        nftMetadata,
        nftMint: mint.publicKey,
        nftMintAuthority: payer,
        token: nftTokenAccount,
        collectionUpdateAuthority: collection.updateAuthorityAddress,
        collectionDelegateRecord,
        collectionMasterEdition,
        collectionMetadata,
        collectionMint,
        tokenMetadataProgram: METAPLEX_PROGRAM_ID,
        systemProgram: SystemProgram.programId,
        sysvarInstructions: SYSVAR_INSTRUCTIONS_PUBKEY,
        splTokenProgram: TOKEN_PROGRAM_ID,
        splAtaProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
        recentSlothashes: SYSVAR_SLOT_HASHES_PUBKEY,
    }

    if (candyMachineObject.version == AccountVersion.V2) {
        accounts.tokenRecord = metaplex
            .nfts()
            .pdas()
            .tokenRecord({ mint: mint.publicKey, token: nftTokenAccount })
    }

    if (!mintArgs) {
        mintArgs = new Uint8Array()
    }

    const args = {
        mintArgs,
        label: label ?? null,
    }

    const ixs = []

    const mintIx = createMintV2Instruction(accounts, args)
    // this test always initializes the mint, we we need to set the
    // account to be writable and a signer to avoid warnings
    for (let i = 0; i < mintIx.keys.length; i++) {
        try {
            if (mintIx.keys[i].pubkey.toBase58() === mint.publicKey.toBase58()) {
                mintIx.keys[i].isSigner = true
                mintIx.keys[i].isWritable = true
            }
        } catch {
            mintIx.keys[i].pubkey = mintIx.keys[i].pubkey.value;
            if (mintIx.keys[i].pubkey.toBase58() === mint.publicKey.toBase58()) {
                mintIx.keys[i].isSigner = true
                mintIx.keys[i].isWritable = true
            }
        }
    }

    if (remainingAccounts) {
        mintIx.keys.push(...remainingAccounts)
    }

    const data = Buffer.from(
        Uint8Array.of(
            0,
            ...new BN(600000).toArray("le", 4),
            ...new BN(0).toArray("le", 4)
        )
    )

    const additionalComputeIx =
        new TransactionInstruction({
            keys: [],
            programId: ComputeBudgetProgram.programId,
            data,
        })

    ixs.push(additionalComputeIx)
    ixs.push(mintIx)

    return { instructions: ixs }

}

export const getRemainingAccountsByGuardType = ({
    candyMachine,
    payer,
    guard,
    guardType,
}) => {
    const remainingAccs = {

        startDate: () => {
            // start date is default
            return {}
        },
        solPayment: () => {

            const solPaymentGuard = guard;

            return {
                accounts: [
                    {
                        pubkey: solPaymentGuard.destination,
                        isSigner: false,
                        isWritable: true,
                    },
                ],
            }
        },
        // allowList: () => {
        //     if (!candyMachine.candyGuard) return {}

        //     const accounts = {
        //         candyGuard: candyMachine.candyGuard.address,
        //         candyMachine: candyMachine.address,
        //         payer,
        //     }

        //     const merkleRoot = getMerkleRoot(allowList)
        //     const validMerkleProof = getMerkleProof(allowList, payer.toString())

        //     const vectorSizeBuffer = Buffer.alloc(4)
        //     u32.write(vectorSizeBuffer, 0, validMerkleProof.length)

        //     // prepares the mint arguments with the merkle proof
        //     const mintArgs = Buffer.concat([vectorSizeBuffer, ...validMerkleProof])

        //     const args = {
        //         args: {
        //             guard: GuardType.AllowList,
        //             data: mintArgs,
        //         },
        //         label: null,
        //     }

        //     const [proofPda] = PublicKey.findProgramAddressSync(
        //         [
        //             Buffer.from("allow_list"),
        //             merkleRoot,
        //             payer.toBuffer(),
        //             candyMachine.candyGuard.address.toBuffer(),
        //             candyMachine.address.toBuffer(),
        //         ],
        //         CANDY_GUARD_PROGRAM_ID
        //     )

        //     const routeIx = createRouteInstruction(accounts, args)
        //     routeIx.keys.push(
        //         ...[
        //             {
        //                 pubkey: proofPda,
        //                 isSigner: false,
        //                 isWritable: true,
        //             },
        //             {
        //                 pubkey: SystemProgram.programId,
        //                 isSigner: false,
        //                 isWritable: false,
        //             },
        //         ]
        //     )

        //     const remainingAccounts = [
        //         {
        //             pubkey: proofPda,
        //             isSigner: false,
        //             isWritable: false,
        //         },
        //     ]

        //     return { ixs: [routeIx], accounts: remainingAccounts }
        // },
        mintLimit: () => {
            if (!candyMachine.candyGuard) return {}
            const mintLimitGuard = guard;

            const [mintCounterPda] = PublicKey.findProgramAddressSync(
                [
                    Buffer.from("mint_limit"),
                    new Uint8Array([mintLimitGuard.id]),
                    payer.toBuffer(),
                    candyMachine.candyGuard?.address.toBuffer(),
                    candyMachine.address.toBuffer(),
                ],
                CANDY_GUARD_PROGRAM_ID
            )

            return {
                accounts: [
                    {
                        pubkey: mintCounterPda,
                        isSigner: false,
                        isWritable: true,
                    },
                ],
            }
        },
    }

    if (!remainingAccs[guardType]) {
        console.warn(
            "Couldn't find remaining accounts for Guard " +
            guardType +
            ". This can most likely cause the mint tx to fail."
        )

        return {}
    }

    return remainingAccs[guardType]()
}

export const getRemainingAccountsForCandyGuard = (
    candyMachine,
    payer
) => {
    if (!candyMachine.candyGuard) return {}

    const { guards } = candyMachine.candyGuard

    /** Filter only enabled Guards */
    const enabledGuardsKeys =
        guards && Object.keys(guards).filter((guardKey) => guards[guardKey])

    let remainingAccounts = []
    let additionalIxs = []
    if (enabledGuardsKeys.length) {
        /** Map all Guards and grab their remaining accounts */
        enabledGuardsKeys.forEach((guardKey) => {
            const guardObject = candyMachine.candyGuard?.guards[guardKey]

            if (!guardObject) return null

            console.log(`Setting up ${guardKey} Guard...`)
            const { accounts, ixs } = getRemainingAccountsByGuardType({
                candyMachine,
                payer,
                guard: guardObject,
                guardType: guardKey,
            })

            /** Push to the accounts array */
            if (accounts && accounts.length) {
                remainingAccounts.push(...accounts)
            }

            /** Push to the ixs array */
            if (ixs && ixs.length) {
                additionalIxs.push(...ixs)
            }
        })
    }

    return { remainingAccounts, additionalIxs }
}