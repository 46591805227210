<template>
    <div>
        <header :class="headerType">
            <div class="header-top">
                <div class="container clearfix" id="top-container">
                    <div class="logo" style="display: flex; align-items: center;">
                        <a href="/" title="TradingTrain 鎚圈">
                            <picture v-if="ifBonkTime">
                                <source media="(max-width: 991px)" srcset="../../assets/images/BonkTimeLogo.png" />
                                <img src="../../assets/images/BonkTimeLogo.png" alt="TradingTrain 鎚圈" width="200" height="80" />
                            </picture>
                            <picture v-else>
                                <source media="(max-width: 991px)" srcset="../../assets/images/logo.png" />
                                <img src="../../assets/images/logo.png" alt="TradingTrain 鎚圈" width="200" height="80" />
                            </picture>
                        </a>
                    </div>

                    <div class="header-search" :class="{ show: headerSearch }">
                        <el-input v-model="searchText" name="" id="headerSearch" :placeholder="$t('search.placeholder')"
                            @keyup.enter="goSearch"></el-input>
                        <div class="header-search-searchBtn" @click="goSearch">
                            <el-icon>
                                <Search />
                            </el-icon>
                        </div>
                    </div>

                    <div class="header-right" :style="isLogin && windowWidth < 768 ? 'padding-right: 30px' : 'padding-right: 0px'">
                        <div class="header-mobileSearch">
                            <label for="headerSearch">
                                <el-icon class="el-icon-search"
                                    @click="headerSearch = !headerSearch; hideMobileNavbar(); headerBell = false;">
                                    <Search />
                                </el-icon>
                            </label>
                        </div>
                        <div class="header-bell" v-if="isLogin == true">
                            <div class="header-bell-btn" :class="{ 'active': headerBell }"
                                @click=" notifyOpened = checkNotify(notifyOpened)">
                                <el-icon class="el-icon-bell"
                                    @click=" headerBell = !headerBell; hideMobileNavbar(); headerSearch = false">
                                    <Bell />
                                </el-icon>
                                <div class="header-bell-num" v-if="notifications != '0'">
                                    {{ notifications }}
                                </div>
                                <div class="header-member-menu notranslate">
                                    <ul class="notify-ul" v-if="notifyHeaderData.length > 0">
                                        <li v-for="( item, index ) in  notifyHeaderData " :key="item.isRead + '_' + index"
                                            :class="{ 'hasDivider': index != 0, 'is_invite': item.notify_type == 'friend_invite' }">
                                            <div class="messageItem" :class="{ 'newMessage': item.isRead == false }"
                                                v-if="item.notify_type == 'friend_invite'">
                                                <el-row :gutter="10">
                                                    <el-col :span="6">
                                                        <img :src="`${item.profile_picture}`" alt=""
                                                            class="messageItem-img" v-if="item.profile_picture != ''">
                                                        <img src="../../assets/images/userImg_base.png" alt=""
                                                            class="messageItem-img" v-else>
                                                    </el-col>
                                                    <el-col :span="18">
                                                        <div class="messageItem-message">
                                                            {{ item.notify_msg }}
                                                        </div>
                                                        <div class="messageItem-btnArea">
                                                            <div class="btn sBtn coffee friend-btn">
                                                                <a href="javascript:void(0);" title="確認"
                                                                    @click=" replyInvite(item.by_user_id, 'accept');">
                                                                    {{ $t('pageHeader.notify.acceptBtn') }}
                                                                </a>
                                                            </div>
                                                            <div class="btn sBtn coffee borderType friend-btn">
                                                                <a href="javascript:void(0);" title="刪除"
                                                                    @click=" replyInvite(item.by_user_id, 'reject');">
                                                                    {{ $t('pageHeader.notify.rejectBtn') }}
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div class="messageItem-time">
                                                            {{ item.create_at }}
                                                        </div>
                                                    </el-col>
                                                </el-row>
                                            </div>
                                            <div class="messageItem" :class="{ 'newMessage': item.isRead == false }"
                                                @click=" readToPage(item.notify_type, item.article_id, item.comment_id, item.by_user_id)"
                                                v-else>
                                                <el-row :gutter="10">
                                                    <el-col :span="6">
                                                        <img :src="`${item.profile_picture}`" alt=""
                                                            class="messageItem-img" v-if="item.profile_picture != ''">
                                                        <img src="../../assets/images/userImg_base.png" alt=""
                                                            class="messageItem-img" v-else>
                                                    </el-col>
                                                    <el-col :span="18">
                                                        <div class="messageItem-message">
                                                            {{ item.notify_msg }}
                                                        </div>
                                                        <div class="messageItem-time">
                                                            {{ item.create_at }}
                                                        </div>
                                                    </el-col>
                                                </el-row>
                                            </div>
                                        </li>
                                        <li class='hasDivider' id="more-li" v-if="notifyHeaderData.length > 0">
                                            <div class='messageItem'>
                                                <el-row :gutter="10">
                                                    <el-col :span="24" style="padding-top: 3px; padding-bottom: 3px;">
                                                        <div class="messageItem-message">
                                                            <router-link to="/notify" class="text-center">{{
                                                                $t('pageHeader.notify.moreBtn') }}</router-link>
                                                        </div>
                                                    </el-col>
                                                </el-row>
                                            </div>
                                        </li>
                                    </ul>
                                    <ul class="notify-ul" v-else>
                                        <li id="no-li">
                                            <div class='messageItem'>
                                                <el-row :gutter="10">
                                                    <el-col :span="24" style="padding-top: 3px; padding-bottom: 3px;">
                                                        <div class="messageItem-message">
                                                            <p class="text-center">{{ $t('pageHeader.notify.noDataBtn') }}
                                                            </p>
                                                        </div>
                                                    </el-col>
                                                </el-row>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="header-bell" v-if="isLogin == false && windowPath != '/mint'" style="padding-top: 35px;">
                            <div class="header-bell-btn" @click=" loginToggle = !loginToggle" style="cursor: pointer">
                                <el-icon class="el-icon-user">
                                    <User />
                                </el-icon>
                                <span v-if="windowWidth > 767">{{ $t('pageHeader.headerText.login') }}{{
                                    $t('pageHeader.headerText.signup') }}</span>
                            </div>
                        </div>

                        <div class="header-wallet" v-if="isLogin || windowPath == '/mint'">
                            <wallet-multi-button></wallet-multi-button>
                        </div>

                        <div class="header-navbar" :class="{ show: navbarShow }" v-if="isLogin == true">

                            <!-- 網頁版 Header Dropdown Start -->
                            <el-dropdown trigger="click" :hide-on-click="false" v-if="windowWidth >= 768">
                                <div class="header-member" :class="{ active: headerMember }"
                                    @click=" headerMember = !headerMember">

                                    <img v-if="profile_picture != ''" :src="`${profile_picture}`" alt=""
                                        class="header-member-img" />
                                    <img v-else src="../../assets/images/userImg_base.png" alt=""
                                        class="header-member-img" />

                                    <img src="../../assets/images/user/藍.png" alt="" class="header-member-img2"
                                        v-if="asset_level == 1" />
                                    <img src="../../assets/images/user/綠.png" alt="" class="header-member-img2"
                                        v-if="asset_level == 2" />
                                    <img src="../../assets/images/user/橘.png" alt="" class="header-member-img2"
                                        v-if="asset_level == 3" />
                                    <img src="../../assets/images/user/黃.png" alt="" class="header-member-img2"
                                        v-else-if="asset_level >= 4" />

                                    <div class="header-member-name">
                                        <div class="username notranslate">{{ username }}</div>
                                        <span class="asset-level" v-if="asset_level > 0">in{{ asset_level }}</span>
                                    </div>
                                    <div class="header-member-info">
                                        <div class="header-member-info-item">
                                            <img src="../../assets/images/icon_crown2.png" alt=""
                                                style="margin-right: 2px;" />
                                            <span v-if="rank != null">{{ rank }}</span>
                                            <span v-else> {{ $t('pageHeader.noRank') }}</span>
                                        </div>
                                        <div class="header-member-info-item margin-right-30">
                                            <img src="../../assets/images/icon_coin.png" alt="" />
                                            {{ convert_portfolio }}
                                        </div>
                                        <!-- <div class="header-member-info-item">
                                            <img src="../../assets/images/header_dropdown/coin.png" width="13" alt="" />
                                            {{ tt_coin }}
                                        </div> -->
                                    </div>
                                    <el-icon class="el-icon-arrow-down">
                                        <ArrowDown />
                                    </el-icon>
                                </div>
                                <template #dropdown>
                                    <el-dropdown-menu>
                                        <span class="dropdown-label notranslate">{{ $t('pageHeader.nav.label.member_level')
                                        }}</span>
                                        <el-dropdown-item id="disable-dropdowm-item">
                                            <div class="custom-dropdown" v-if="isGenesis == false && isWalletConnected">
                                                <div class="label-row notranslate">
                                                    <img src="../../assets/images/header_dropdown/normal_user.png"
                                                        width="18">
                                                    {{ $t('pageHeader.nav.member.general') }}
                                                </div>
                                            </div>
                                            <div class="custom-dropdown"
                                                v-else-if="isGenesis == true && isWalletConnected">
                                                <div class="label-row notranslate">
                                                    <img src="../../assets/images/header_dropdown/vip-card.png" width="18">
                                                    {{ $t('pageHeader.nav.member.vvip') }}
                                                </div>
                                            </div>
                                            <div class="custom-dropdown notranslate" v-else-if="!isWalletConnected">
                                                {{ $t('pageHeader.nav.member.no_wallet') }}
                                            </div>
                                            <div class="custom-dropdown notranslate" v-else>
                                                {{ $t('pageHeader.nav.member.loading') }}
                                            </div>
                                        </el-dropdown-item>
                                        <span class="dropdown-label notranslate">{{ $t('pageHeader.nav.label.language')
                                        }}</span>
                                        <el-dropdown-item>
                                            <!-- <v-google-translate :default-language-code="currentLocale"></v-google-translate> -->
                                            <el-dropdown trigger="hover" placement="right-start"
                                                :popper-options="popperOptions" style="line-height: 20px;"
                                                :hide-on-click="true">
                                                <div class="custom-dropdown">
                                                    <div class="label-row">
                                                        <img src="../../assets/images/header_dropdown/en-language.png"
                                                            width="18" v-if="currentLocale == 'en'">
                                                        <img src="../../assets/images/header_dropdown/ch-language.png"
                                                            width="18" v-else-if="currentLocale == 'zh-TW'">
                                                        <p class="notranslate" v-if="currentLocale == 'en'">{{
                                                            $t('language.en') }}</p>
                                                        <p class="notranslate" v-else-if="currentLocale == 'zh-TW'">
                                                            {{ $t('language.ch') }}
                                                        </p>
                                                    </div>
                                                    <el-icon class="el-icon-arrow-right">
                                                        <ArrowRight />
                                                    </el-icon>
                                                </div>
                                                <template #dropdown>
                                                    <el-dropdown-menu>
                                                        <el-dropdown-item @click=" switchLanguage('en');">
                                                            <div class="sub-dropdown notranslate">
                                                                <img src="../../assets/images/header_dropdown/en-language.png"
                                                                    width="18">
                                                                {{ $t('language.en') }}
                                                            </div>
                                                        </el-dropdown-item>
                                                        <el-dropdown-item @click=" switchLanguage('zh-TW');">
                                                            <div class="sub-dropdown notranslate">
                                                                <img src="../../assets/images/header_dropdown/ch-language.png"
                                                                    width="18">
                                                                {{ $t('language.ch') }}
                                                            </div>
                                                        </el-dropdown-item>
                                                    </el-dropdown-menu>
                                                </template>
                                            </el-dropdown>
                                        </el-dropdown-item>
                                        <span class="dropdown-label notranslate">{{ $t('pageHeader.nav.label.function_menu')
                                        }}</span>
                                        <el-dropdown-item @click=" goToPage('/mypage')">
                                            <div class="custom-dropdown">
                                                <!-- <i class="el-icon-s-home"></i> -->
                                                <div class="label-row notranslate">
                                                    <el-icon>
                                                        <HomeFilled />
                                                    </el-icon>
                                                    {{ $t('pageHeader.nav.mypage') }}
                                                </div>
                                            </div>
                                        </el-dropdown-item>
                                        <el-dropdown-item>
                                            <el-dropdown trigger="hover" placement="right-start"
                                                :popper-options="popperOptions" style="line-height: 20px;">
                                                <div class="custom-dropdown">
                                                    <div class="label-row notranslate">
                                                        <el-icon class="el-icon-user-solid">
                                                            <UserFilled />
                                                        </el-icon>
                                                        <!-- <i class="el-icon-user-solid"></i> -->
                                                        {{ $t('pageHeader.nav.personal_service.title') }}
                                                    </div>
                                                    <el-icon class="el-icon-arrow-right">
                                                        <ArrowRight />
                                                    </el-icon>
                                                </div>
                                                <template #dropdown>
                                                    <el-dropdown-menu>
                                                        <el-dropdown-item @click=" goToPage('/self_info')">
                                                            <div class="sub-dropdown notranslate">
                                                                <img src="../../assets/images/header_dropdown/edit.png"
                                                                    width="18">
                                                                {{ $t('pageHeader.nav.personal_service.menu.edit_profile')
                                                                }}
                                                            </div>
                                                        </el-dropdown-item>
                                                        <el-dropdown-item @click=" goToPage('/manage_friend')">
                                                            <div class="sub-dropdown notranslate">
                                                                <img src="../../assets/images/header_dropdown/friends.png"
                                                                    width="18">
                                                                {{ $t('pageHeader.nav.personal_service.menu.manage_friend')
                                                                }}
                                                            </div>
                                                        </el-dropdown-item>
                                                        <el-dropdown-item @click=" goToPage('/manage_sub')">
                                                            <div class="sub-dropdown notranslate">
                                                                <img src="../../assets/images/header_dropdown/subscribe.png"
                                                                    width="18">
                                                                {{
                                                                    $t('pageHeader.nav.personal_service.menu.manage_subscribe')
                                                                }}
                                                            </div>
                                                        </el-dropdown-item>
                                                        <el-dropdown-item @click=" goToPage('/mail')">
                                                            <div class="sub-dropdown notranslate">
                                                                <img src="../../assets/images/header_dropdown/chat.png"
                                                                    width="18">
                                                                {{ $t('pageHeader.nav.personal_service.menu.inmail') }}
                                                            </div>
                                                        </el-dropdown-item>
                                                    </el-dropdown-menu>
                                                </template>
                                            </el-dropdown>
                                        </el-dropdown-item>
                                        <el-dropdown-item @click=" goToPage('/leaderboard')">
                                            <div class="custom-dropdown">
                                                <div class="label-row notranslate">
                                                    <img src="../../assets/images/header_dropdown/ranking.png" width="18">
                                                    {{ $t('pageHeader.nav.leaderboard') }}
                                                </div>
                                            </div>
                                        </el-dropdown-item>
                                        <el-dropdown-item>
                                            <el-dropdown trigger="hover" placement="right-start"
                                                :popper-options="popperOptions" style="line-height: 20px;">
                                                <div class="custom-dropdown">
                                                    <div class="label-row notranslate">
                                                        <img src="../../assets/images/header_dropdown/customer.png"
                                                            width="18">
                                                        {{ $t('pageHeader.nav.website_service.title') }}
                                                    </div>
                                                    <el-icon class="el-icon-arrow-right">
                                                        <ArrowRight />
                                                    </el-icon>
                                                </div>
                                                <template #dropdown>
                                                    <el-dropdown-menu>
                                                        <el-dropdown-item @click=" goToPage('/wishing_tree')">
                                                            <div class="sub-dropdown notranslate">
                                                                <img src="../../assets/images/header_dropdown/star.png"
                                                                    width="18">
                                                                {{ $t('pageHeader.nav.website_service.menu.wishing_tree') }}
                                                            </div>
                                                        </el-dropdown-item>
                                                        <el-dropdown-item @click=" goToPage('/bank')">
                                                            <div class="sub-dropdown notranslate">
                                                                <img src="../../assets/images/header_dropdown/bank.png"
                                                                    width="18">
                                                                {{ $t('pageHeader.nav.website_service.menu.bank') }}
                                                            </div>
                                                        </el-dropdown-item>
                                                        <el-dropdown-item @click="redeemCodeToggle = !redeemCodeToggle">
                                                            <div class="sub-dropdown notranslate">
                                                                <img src="../../assets/images/header_dropdown/ticket.png"
                                                                    width="18">
                                                                {{ $t('pageHeader.nav.website_service.menu.redeem') }}
                                                            </div>
                                                        </el-dropdown-item>
                                                    </el-dropdown-menu>
                                                </template>
                                            </el-dropdown>
                                        </el-dropdown-item>
                                        <el-dropdown-item>
                                            <el-dropdown trigger="hover" placement="right-start" :hide-on-click="true"
                                                :popper-options="popperOptions" style="line-height: 20px;">
                                                <div class="custom-dropdown">
                                                    <div class="label-row notranslate">
                                                        <img src="../../assets/images/header_dropdown/game.png" width="18">
                                                        {{ $t('pageHeader.nav.start_game.title') }}
                                                    </div>
                                                    <el-icon class="el-icon-arrow-right">
                                                        <ArrowRight />
                                                    </el-icon>
                                                </div>
                                                <template #dropdown>
                                                    <el-dropdown-menu v-if="in_game">
                                                        <el-dropdown-item @click=" goToPage('/game');">
                                                            <div class="sub-dropdown notranslate">
                                                                <img src="../../assets/images/header_dropdown/continue.png"
                                                                    width="18">
                                                                {{ $t('pageHeader.nav.start_game.menu.continue') }}
                                                            </div>
                                                        </el-dropdown-item>
                                                        <el-dropdown-item @click=" gameOverToggle = !gameOverToggle">
                                                            <div class="sub-dropdown notranslate">
                                                                <img src="../../assets/images/header_dropdown/ending.png"
                                                                    width="18">
                                                                {{ $t('pageHeader.nav.start_game.menu.end_game') }}
                                                            </div>
                                                        </el-dropdown-item>
                                                    </el-dropdown-menu>
                                                    <el-dropdown-menu v-else>
                                                        <span class="dropdown-label notranslate">{{
                                                            $t('pageHeader.nav.start_game.label.crypto') }}</span>
                                                        <el-dropdown-item @click=" cryptoToggle = !cryptoToggle">
                                                            <div class="sub-dropdown notranslate">
                                                                <img src="../../assets/images/header_dropdown/cryptocurrency.png"
                                                                    width="18">
                                                                {{ $t('pageHeader.nav.start_game.menu.crypto_currency') }}
                                                            </div>
                                                        </el-dropdown-item>
                                                        <span class="dropdown-label notranslate">{{
                                                            $t('pageHeader.nav.start_game.label.stock') }}</span>
                                                        <el-dropdown-item
                                                            @click=" applyLoanToggle = checkPortfolioRule(applyLoanToggle, 'sii')">
                                                            <div class="sub-dropdown notranslate">
                                                                <img src="../../assets/images/header_dropdown/taiwan.png"
                                                                    width="18">
                                                                {{ $t('pageHeader.nav.start_game.menu.tw_sii_stock') }}
                                                            </div>
                                                        </el-dropdown-item>
                                                        <el-dropdown-item
                                                            @click=" applyLoanToggle = checkPortfolioRule(applyLoanToggle, 'rotc');">
                                                            <div class="sub-dropdown notranslate">
                                                                <img src="../../assets/images/header_dropdown/taiwan.png"
                                                                    width="18">
                                                                {{ $t('pageHeader.nav.start_game.menu.tw_rotc_stock') }}
                                                            </div>
                                                        </el-dropdown-item>
                                                        <el-dropdown-item
                                                            @click=" applyLoanToggle = checkPortfolioRule(applyLoanToggle, 'us');">
                                                            <div class="sub-dropdown notranslate">
                                                                <img src="../../assets/images/header_dropdown/united-states.png"
                                                                    width="18">
                                                                {{ $t('pageHeader.nav.start_game.menu.us_stock') }}
                                                            </div>
                                                        </el-dropdown-item>
                                                        <span class="dropdown-label notranslate">{{
                                                            $t('pageHeader.nav.start_game.label.futures') }}</span>
                                                        <el-dropdown-item
                                                            @click=" futuresToggle = !futuresToggle">
                                                            <div class="sub-dropdown notranslate">
                                                                <img src="../../assets/images/header_dropdown/taiwan.png"
                                                                    width="18">
                                                                {{ $t('pageHeader.nav.start_game.menu.tx_futures') }}
                                                            </div>
                                                        </el-dropdown-item>
                                                        <el-dropdown-item
                                                            @click=" nqToggle = !nqToggle">
                                                            <div class="sub-dropdown notranslate">
                                                                <img src="../../assets/images/header_dropdown/united-states.png"
                                                                    width="18">
                                                                {{ $t('pageHeader.nav.start_game.menu.nq_futures') }}
                                                            </div>
                                                        </el-dropdown-item>
                                                        <el-dropdown-item v-if="level >= 10"
                                                            @click=" applyLoanToggle = checkPortfolioRule(applyLoanToggle, 'GC1_1D');">
                                                            <div class="sub-dropdown notranslate">
                                                                <img src="../../assets/images/header_dropdown/gold.png"
                                                                    width="18">
                                                                {{ $t('pageHeader.nav.start_game.menu.gold_futures') }}
                                                            </div>
                                                        </el-dropdown-item>
                                                        <el-dropdown-item v-if="level >= 10"
                                                            @click=" applyLoanToggle = checkPortfolioRule(applyLoanToggle, 'CO1_1D');">
                                                            <div class="sub-dropdown notranslate">
                                                                <img src="../../assets/images/header_dropdown/oil.png"
                                                                    width="18">
                                                                {{ $t('pageHeader.nav.start_game.menu.brent_crude_futures')
                                                                }}
                                                            </div>
                                                        </el-dropdown-item>
                                                        <el-dropdown-item v-if="level >= 10"
                                                            @click=" applyLoanToggle = checkPortfolioRule(applyLoanToggle, 'CL1_1D');">
                                                            <div class="sub-dropdown notranslate">
                                                                <img src="../../assets/images/header_dropdown/crude-oil.png"
                                                                    width="18">
                                                                {{ $t('pageHeader.nav.start_game.menu.wti_futures') }}
                                                            </div>
                                                        </el-dropdown-item>
                                                    </el-dropdown-menu>
                                                </template>
                                            </el-dropdown>
                                        </el-dropdown-item>
                                        <el-dropdown-item @click=" goToPage('/competition/home')">
                                            <div class="custom-dropdown">
                                                <div class="label-row notranslate">
                                                    <img src="../../assets/images/header_dropdown/competition.png" width="18">
                                                    {{ $t('pageHeader.nav.competitionHome') }}
                                                </div>
                                            </div>
                                        </el-dropdown-item>
                                        <el-dropdown-item @click=" goToPage('/mint')">
                                            <div class="custom-dropdown">
                                                <div class="label-row notranslate">
                                                    <img src="../../assets/images/header_dropdown/nft.png" width="18">
                                                    {{ $t('pageHeader.nav.buy_nfts') }}
                                                </div>
                                            </div>
                                        </el-dropdown-item>
                                        <el-dropdown-item @click=" goToPage('/referral')">
                                            <div class="custom-dropdown">
                                                <div class="label-row notranslate">
                                                    <img src="../../assets/images/header_dropdown/referral.png" width="18">
                                                    {{ $t('pageHeader.nav.referral') }}
                                                </div>
                                            </div>
                                        </el-dropdown-item>
                                        <el-dropdown-item @click="Logout">
                                            <div class="custom-dropdown" id="logout-dropdown">
                                                <div class="label-row notranslate">
                                                    <img src="../../assets/images/header_dropdown/logout.png" width="18">
                                                    {{ $t('pageHeader.nav.logout') }}
                                                </div>
                                            </div>
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </template>
                            </el-dropdown>
                            <!-- 網頁版 Header Dropdown End -->

                            <!-- 手機版 Header Dropdown Start -->
                            <div class="header-member" :class="{ active: headerMember }"
                                @click=" headerMember = !headerMember" v-if="windowWidth < 768">

                                <img v-if="profile_picture != ''" :src="`${profile_picture}`" alt=""
                                    class="header-member-img" />
                                <img v-else src="../../assets/images/userImg_base.png" alt="" class="header-member-img" />

                                <img src="../../assets/images/user/藍.png" alt="" class="header-member-img2"
                                    v-if="asset_level == 1" />
                                <img src="../../assets/images/user/綠.png" alt="" class="header-member-img2"
                                    v-if="asset_level == 2" />
                                <img src="../../assets/images/user/橘.png" alt="" class="header-member-img2"
                                    v-if="asset_level == 3" />
                                <img src="../../assets/images/user/黃.png" alt="" class="header-member-img2"
                                    v-else-if="asset_level >= 4" />

                                <div class="header-member-name">
                                    <div class="username notranslate">{{ username }}</div>
                                    <span class="asset-level" v-if="asset_level > 0">in{{ asset_level }}</span>
                                </div>

                                <div class="label-row" style="color: white; display: flex; align-items: center;"
                                    v-if="isGenesis == false && isWalletConnected">
                                    <img src="../../assets/images/header_dropdown/normal_user.png" width="18">
                                    &nbsp;{{ $t('pageHeader.nav.member.general') }}
                                </div>

                                <div class="label-row" style="color: white; display: flex; align-items: center;"
                                    v-else-if="isGenesis == true && isWalletConnected">
                                    <img src="../../assets/images/header_dropdown/vip-card.png" width="18">
                                    &nbsp;{{ $t('pageHeader.nav.member.vvip') }}
                                </div>

                                <div class="label-row" style="color: white; display: flex; align-items: center;" v-else-if="!isWalletConnected">
                                    {{ $t('pageHeader.nav.member.no_wallet') }}
                                </div>
                                
                                <div class="label-row" style="color: white; display: flex; align-items: center;" v-else>
                                    {{ $t('pageHeader.nav.member.loading') }}
                                </div>

                                <div class="header-member-info">
                                    <div class="header-member-info-item">
                                        <img src="../../assets/images/icon_crown2.png" alt="" style="margin-right: 2px;" />
                                        <span v-if="rank != null">{{ rank }}</span>
                                        <span v-else> {{ $t('pageHeader.noRank') }}</span>
                                    </div>
                                    <div class="header-member-info-item">
                                        <img src="../../assets/images/icon_coin.png" alt="" />
                                        {{ convert_portfolio }}
                                    </div>
                                    <!-- <div class="header-member-info-item">
                                        <img src="../../assets/images/header_dropdown/coin.png" width="13" alt="" />
                                        {{ tt_coin }}
                                    </div> -->
                                </div>
                                <i class="el-icon-arrow-down"></i>
                            </div>

                            <nav class="header-navbar-nav">
                                <ul>
                                    <li>
                                        <a href="/mypage" title="我的主頁">
                                            我的主頁
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/self_info" title="編輯個人資訊">
                                            編輯個人資訊
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/manage_friend" title="管理好友">
                                            管理好友
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/manage_sub" title="管理訂閱">
                                            管理訂閱
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/mail" title="站內信">
                                            站內信
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/game_lobby" title="遊戲大廳">
                                            遊戲大廳
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/competition/home" title="競賽大廳">
                                            競賽大廳
                                        </a>
                                    </li>
                                    <li class="hasDivider">
                                        <a href="/leaderboard" title="伺服器排名">
                                            伺服器排名
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/wishing_tree" title="許願樹">
                                            許願樹
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/bank" title="銀行">
                                            銀行
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);" title="輸入兌換碼"
                                            @click="redeemCodeToggle = !redeemCodeToggle">
                                            輸入兌換碼
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/referral" title="推薦連結">
                                            推薦連結
                                        </a>
                                    </li>
                                    <li class="hasDivider">
                                        <a href="/mint" title="NFT預購" target="_blank">
                                            購買NFT
                                        </a>
                                        <!-- <a href="https://www.tdtn.tw/home/" title="NFT預購" target="_blank">
                                            NFT預購
                                        </a> -->
                                    </li>
                                    <li class="hasDivider">
                                        <a href="/" title="回首頁">
                                            回首頁
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)" title="登出" @click="Logout">
                                            登出
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                            <!-- 手機版 Header Dropdown End -->

                        </div>
                    </div>

                    <!--手機menu按鍵-->
                    <div class="menuBtn" :class="{ active: navbarShow }"
                        @click=" toggleMobileNavbar(); headerBell = false; headerSearch = false" v-if="isLogin">
                        <span> </span>
                        <span> </span>
                        <span> </span>
                        <span> </span>
                        <span> </span>
                    </div>
                </div>
            </div>
            <div class="index-header" v-if="headerType == 'header'">
                <div class="container notranslate">
                    <h1 class="index-header-tit" title="遊戲交易都贏不了，何必實單當韭菜！">
                        {{ $t('pageHeader.title.top1') }}<br v-if="windowWidth < 768" />{{ $t('pageHeader.title.top2') }}
                    </h1>
                    <div class="index-header-stit">
                        {{ $t('pageHeader.title.second') }}
                    </div>
                    <img src="../../assets/images/indexHeadShadow.png" alt="" class="index-header-shadow" />
                    <p class="index-header-text">
                        {{ $t('pageHeader.title.third') }}
                        <br />{{ $t('pageHeader.title.forth.1') }}<br v-if="windowWidth < 475"/>{{ $t('pageHeader.title.forth.2') }}
                        <br />{{ $t('pageHeader.title.fifth.1') }}<br v-if="windowWidth < 475"/>{{ $t('pageHeader.title.fifth.2') }}
                        <br />{{ $t('pageHeader.title.sixth') }}
                    </p>
                </div>
            </div>
            <nav class="navbar">
                <div class="container">
                    <ul>
                        <li :class="{ active: isActive1 }" class="notranslate">
                            <a href="/game_lobby" title="遊戲大廳">
                                <picture>
                                    <source media="(max-width: 991px)" srcset="../../assets/images/icon_nav1_1@2.png" />
                                    <img src="../../assets/images/icon_nav1_1.png" alt="遊戲大廳" class="navbar-btn-icon-1" />
                                </picture>
                                <picture>
                                    <source media="(max-width: 991px)" srcset="../../assets/images/icon_nav1_2@2.png" />
                                    <img src="../../assets/images/icon_nav1_2.png" alt="遊戲大廳" class="navbar-btn-icon-2" />
                                </picture>
                                <br />{{ $t('pageHeader.menu_btn.game_lobby') }}
                            </a>
                        </li>
                        <li :class="{ active: isActive4 }" class="notranslate">
                            <a href="/claim" title="兌換代幣">
                                <picture>
                                    <source media="(max-width: 991px)" srcset="../../assets/images/icon_nav4_1@2.png" />
                                    <img src="../../assets/images/icon_nav4_1.png" alt="兌換代幣" class="navbar-btn-icon-1" />
                                </picture>
                                <picture>
                                    <source media="(max-width: 991px)" srcset="../../assets/images/icon_nav4_2@2.png" />
                                    <img src="../../assets/images/icon_nav4_2.png" alt="兌換代幣" class="navbar-btn-icon-2" />
                                </picture>
                                <br />{{ $t('pageHeader.menu_btn.claimToken') }}
                            </a>
                        </li>
                        <li :class="{ active: isActive2 }" class="notranslate">
                            <a href="/mint" title="鎚圈現正募資中">
                                <!-- <a href="https://www.tdtn.tw/home/" title="鎚圈現正募資中" target="_blank"> -->
                                <picture>
                                    <source media="(max-width: 991px)" srcset="../../assets/images/icon_nav3_1@2.png" />
                                    <img src="../../assets/images/icon_nav3_1.png" alt="線上商城" class="navbar-btn-icon-1" />
                                </picture>
                                <picture>
                                    <source media="(max-width: 991px)" srcset="../../assets/images/icon_nav3_2@2.png" />
                                    <img src="../../assets/images/icon_nav3_2.png" alt="線上商城" class="navbar-btn-icon-2" />
                                </picture>
                                <br />{{ $t('pageHeader.menu_btn.buy_ntfs') }}
                            </a>
                        </li>
                        <li :class="{ active: isActive3 }" class="notranslate">
                            <a href="/forum" title="討論區">
                                <picture>
                                    <source media="(max-width: 991px)" srcset="../../assets/images/icon_nav2_1@2.png" />
                                    <img src="../../assets/images/icon_nav2_1.png" alt="討論區" class="navbar-btn-icon-1" />
                                </picture>
                                <picture>
                                    <source media="(max-width: 991px)" srcset="../../assets/images/icon_nav2_2@2.png" />
                                    <img src="../../assets/images/icon_nav2_2.png" alt="討論區" class="navbar-btn-icon-2" />
                                </picture>
                                <br />{{ $t('pageHeader.menu_btn.forum') }}
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>

        <login-modal :toggle="loginToggle"></login-modal>
        <futures-mode-modal :futures-toggle="futuresToggle" :crypto-toggle="cryptoToggle" :nq-toggle="nqToggle"></futures-mode-modal>
        <game-over-modal :toggle="gameOverToggle"></game-over-modal>
        <apply-loan-modal :toggle="applyLoanToggle"></apply-loan-modal>
        <redeem-code-modal :toggle="redeemCodeToggle"></redeem-code-modal>
    </div>
</template>

<script>
import { WalletMultiButton } from 'solana-wallets-vue';
import futuresModeModal from '../modal/futuresModeModal.vue';
import loginModal from '../modal/loginModal.vue';
import gameOverModal from '../modal/gameOverModal.vue';
import applyLoanModal from '../modal/applyLoanModal.vue';
import redeemCodeModal from '../modal/redeemCodeModal.vue';
import {
    useWorkspace,
    useMetaplex,
    connectWallet,
    Logout,
    getNotifyHeader,
    checkNotify,
    readToPage,
    apiData,
    checkPortfolioRule,
    initMetaplex
} from '@/composables';
import { useUserStore, useCommonStore, useNotifyStore } from '@/store';
import { computed, reactive, watch, ref, onMounted } from 'vue';
import axios from 'axios';
import { ElMessage, ElNotification } from 'element-plus';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { PublicKey } from '@metaplex-foundation/js';

// import vGoogleTranslate from './vGoogleTranslate.vue';

export default {
    name: 'zhHeader',
    components: {
        WalletMultiButton,
        futuresModeModal,
        loginModal,
        gameOverModal,
        applyLoanModal,
        redeemCodeModal
        // vGoogleTranslate
    },
    props: {
        headerType: {
            type: String,
            default: "header type2"
        },
        isActive1: {
            type: Boolean,
            default: false
        },
        isActive2: {
            type: Boolean,
            default: false
        },
        isActive3: {
            type: Boolean,
            default: false
        },
        isActive4: {
            type: Boolean,
            default: true
        }
    },
    setup() {

        // 斷開錢包 listsener
        const addWalletListener = () => {

            const disConnectBtn = document.getElementsByClassName('swv-dropdown-list-item')[2];
            disConnectBtn.addEventListener('click', async () => {

                const walletName = wallet.wallet.value.adapter.name;
                if (walletName == 'Phantom') {
                    await window.phantom.solana.disconnect();
                } else if (walletName == 'Backpack') {
                    await window.backpack.disconnect();
                } else if (walletName == 'Glow') {
                    await window.glow.disconnect();
                }

                userStore.isGenesis = false;

            });
        }

        // 讀取notifications
        const notifyStore = useNotifyStore();
        const notifications = computed(() => notifyStore.notifications);
        const notifyOpened = ref(false);
        const notifyHeaderData = computed(() => notifyStore.notifyHeaderData);
        const ifBonkTime = computed(() => notifyStore.ifBonkTime)

        const replyInvite = async (fromUserId, replyType) => {

            const { userToken, apiBaseurl } = apiData();

            await axios({
                method: 'post',
                url: apiBaseurl + '/friend/invite/reply',
                headers: {
                    Authorization: "Bearer " + userToken
                },
                data: {
                    reply_type: replyType,
                    friend_id: fromUserId
                }
            }).then(async () => {

                const objIndex = notifyHeaderData.value.findIndex((
                    obj => obj.notify_type == "friend_invite" && obj.by_user_id == fromUserId
                ));
                notifyHeaderData.value.splice(objIndex, 1);
                // 還有 awards 部分

                // header
                await getNotifyHeader();

            }).catch(error => {
                const error_msg = error.response.data;
                ElMessage({
                    showClose: true,
                    type: 'error',
                    message: error_msg.Status.Desc,
                    duration: 1500
                });
            })
        }

        // 讀取使用者資料
        const userStore = useUserStore();
        const username = computed(() => userStore.username);
        const profile_picture = computed(() => userStore.profile_picture);
        const rank = computed(() => userStore.rank);
        const convert_portfolio = computed(() => userStore.convert_portfolio);
        const tt_coin = computed(() => userStore.tt_coin);
        const level = computed(() => userStore.level);
        const in_game = computed(() => userStore.in_game);
        const isLogin = computed(() => userStore.isLogin);
        const isWalletConnected = computed(() => userStore.isWalletConnected);
        const isGenesis = computed(() => userStore.isGenesis);
        const asset_level = computed(() => userStore.asset_level);

        // 讀取共用資料
        const commonStore = useCommonStore();
        const headerSearch = ref(commonStore.headerSearch);
        const headerBell = ref(commonStore.headerBell);
        const headerMember = ref(commonStore.headerMember);
        const navbarShow = ref(commonStore.navbarShow);
        const windowWidth = computed(() => commonStore.windowWidth);
        const windowPath = computed(() => commonStore.windowPath);
        const genesisCollectionAddress = computed(() => commonStore.genesisCollectionAddress);

        // 使用者登入之帳號密碼
        const loginInput = reactive({
            account: "",
            password: ""
        });

        // 取得錢包及錢包地址
        const { wallet } = useWorkspace();
        const walletAddress = computed(() => wallet.publicKey.value?.toString());

        const getGenesisList = async () => {
            const metaplex = useMetaplex();
            const allNfts = await metaplex.nfts().findAllByOwner({ owner: new PublicKey(walletAddress.value) });
            const genesisNfts = allNfts.filter(elem => elem.collection?.address.toBase58() == genesisCollectionAddress.value);
            if (genesisNfts.length > 0) {
                userStore.isGenesis = true;
            } else {
                userStore.isGenesis = false;
            }
        }

        // 監聽錢包地址變化
        watch(walletAddress, async (newAddress) => {

            const walletName = wallet.wallet.value.adapter.name;
            await window.$cookies.set("walletName", walletName, "1d", "/", null, true, "None");

            if (newAddress && isLogin.value) {
                initMetaplex();
                await connectWallet();
                addWalletListener();
                await getGenesisList();
            } else {
                userStore.isWalletConnected = false;
            }

        });

        // header dropdown 的轉向方式
        const popperOptions = reactive({
            placement: 'right-start',
            modifiers: [
                {
                    name: 'flip',
                    options: {
                        fallbackPlacements: ['left-start', 'right-start'],
                    },
                },
            ]
        });

        const loginToggle = ref(false);

        // go to search page
        const searchText = ref("");
        const goSearch = () => {
            window.location.href = '/search?search_name=' + searchText.value;
        }

        // web push function
        const getFCMToken = async () => {
            const messaging = getMessaging();

            await getToken(
                messaging,
                { vapidKey: 'BGPbnkZOjfbEOuNuX1JMmZhbhCygyaMR7FHv-3aCCKaTNvru5HgOOMqCtbjc_lljh3_PHGITOIZQL241T23q_pc' }
            ).then(async (currentToken) => {
                if (currentToken) {
                    const { userToken, apiBaseurl } = apiData();

                    await axios({
                        method: 'post',
                        url: apiBaseurl + '/user/token/update',
                        headers: {
                            Authorization: "Bearer " + userToken
                        },
                        data: {
                            push_token: currentToken
                        }
                    }).then(() => {
                        window.$cookies.set("pushToken", currentToken, "1d", "/");
                    })
                } else {
                    console.log('No registration token available. Request permission to generate one.');
                }
            }).catch(err => {
                console.log('An error occurred while retrieving token. ', err);
            })
        }

        const receiveFCM = () => {
            const messaging = getMessaging();

            onMessage(messaging, (payload) => {

                console.log(payload);

                let msg = '<div style="display: flex; align-items: flex-start; cursor: pointer;">'
                if (payload.data.picture != "") {
                    msg += '<img src="' + payload.data.picture + '" style="border-radius: 5px; width: 50px; height: 50px;">'
                } else {
                    var base_img = require('../../assets/images/userImg_base.png')
                    msg += '<img src="' + base_img + '" style="border-radius: 5px; width: 50px; height: 50px;">'
                }
                msg += '<p style="margin-left: 10px!important; color: black;">'
                msg += payload.data.body
                msg += '</p>'
                msg += '</div>'

                ElNotification({
                    dangerouslyUseHTMLString: true,
                    message: msg,
                    position: 'top-right',
                    duration: 5000,
                    onClick: function () {
                        window.location.href = payload.data.url;
                    }
                })

            })
        }

        if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && isLogin.value) {
            getFCMToken();
            receiveFCM();
        }

        const futuresToggle = ref(false);
        const cryptoToggle = ref(false);
        const nqToggle = ref(false);
        const gameOverToggle = ref(false);
        const applyLoanToggle = ref(false);
        const redeemCodeToggle = ref(false);

        // 目前使用語言
        const currentLocale = computed(() => commonStore.localeSetting);

        onMounted(async () => {

            // 取得通知列表
            getNotifyHeader(currentLocale.value);

            // 連接錢包並判定是否有 genesis collection
            if (wallet.publicKey.value?.toBase58()) {
                
                await connectWallet();
                addWalletListener();
                await getGenesisList();

            } else if (window.$cookies.isKey("userToken") && /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {

                console.log("reconnect to wallet");
                setTimeout(async () => {
                    const walletName = window.$cookies.get("walletName");
                    await wallet.select(walletName);
                }, 200);

            }

        });

        return {
            notifications,
            notifyOpened,
            notifyHeaderData,
            ifBonkTime,
            username,
            profile_picture,
            rank,
            convert_portfolio,
            tt_coin,
            level,
            in_game,
            isLogin,
            isWalletConnected,
            isGenesis,
            asset_level,
            commonStore,
            userStore,
            headerSearch,
            headerBell,
            headerMember,
            navbarShow,
            windowWidth,
            windowPath,
            searchText,
            wallet,
            walletAddress,
            loginInput,
            popperOptions,
            loginToggle,
            futuresToggle,
            cryptoToggle,
            nqToggle,
            gameOverToggle,
            applyLoanToggle,
            redeemCodeToggle,
            currentLocale,
            checkPortfolioRule,
            checkNotify,
            readToPage,
            replyInvite,
            Logout,
            goSearch
        }
    },
    methods: {
        switchLanguage(locale) {

            window.$cookies.set('localeSetting', locale, '365d', '/');
            this.$i18n.locale = locale;
            this.commonStore.localeSetting = locale;

            // 如果切換成 en 後，未連結錢包，則自動登出
            if (locale == 'en' && !this.isWalletConnected) {
                window.$cookies.remove("userToken");
                this.userStore.isLogin = false;
                this.userStore.user_id = null;
            }

            setTimeout(async () => {

                const name = this.$route.name;
                const noChangeList = ['level_game', 'mission_level', 'article_detail', 'game_detail', 'competitionRank']
                if (noChangeList.includes(name) == false) {
                    document.title = this.$t(`routes.${this.$route.meta.title}.title`);
                }
                await getNotifyHeader(locale);
            }, 200);
        },
        toggleModal: function (t) {
            this.$refs[t].toggle = !this.$refs[t].toggle;
        },
        toggleMobileNavbar() {
            this.menuBtnActive === false ? (this.menuBtnActive = true) : (this.menuBtnActive = false);
            this.navbarShow === false ? (this.navbarShow = true) : (this.navbarShow = false);
        },
        hideMobileNavbar() {
            this.menuBtnActive = false;
            this.navbarShow = false;
        },
        goToPage(href) {
            if (href == 'https://www.tdtn.tw/home/') {
                window.open(href, '_blank');
            } else {
                window.location.href = href;
            }
        },
    }
}
</script>

<style scoped>@import url("../../assets/css/pageheader.css");</style>