import axios from "axios";
import { apiData } from "./useCommon";
import { useCommonStore, useForumStore, useUserStore } from "@/store";
import { ElMessage, ElMessageBox } from "element-plus";

export const getArticleList = async (page, lang = 'zh-TW') => {

    const { apiBaseurl } = apiData();
    const store = useForumStore();

    await axios({
        method: "get",
        url: apiBaseurl + '/forum/article/list',
        params: {
            forum_id: store.forum_id,
            page: page,
            lang: lang
        }
    }).then(response => {

        var article_data = response.data.Result.article_data;
        store.article_items = article_data.items;
        store.total_items = article_data.total_items;

        document.getElementById("forum-top").scrollIntoView();

    }).catch(error => {
        var err = error.response.data;
        ElMessage({
            showClose: true,
            type: 'error',
            message: err.Status.Desc,
            duration: 1500
        });
    })
}

export const getArticleInfo = async (article_id) => {

    const { apiBaseurl } = apiData();
    const forumStore = useForumStore();
    const commonStore = useCommonStore();

    await axios({
        method: "get",
        url: apiBaseurl + '/forum/article/info',
        params: {
            article_id: article_id
        }
    }).then(response => {

        const result = response.data.Result;
        forumStore.article_data = result.article_data;
        forumStore.author_data = result.author_data;

        if (commonStore.localeSetting == 'en') {

            if (forumStore.article_data.forum_id == 1) {

                forumStore.article_data.forum_name = 'Announcement';

            } else if (forumStore.article_data.forum_id == 2) {

                forumStore.article_data.forum_name = 'Technical Analysis';

            } else if (forumStore.article_data.forum_id == 3) {

                forumStore.article_data.forum_name = 'Market Trend';

            } else if (forumStore.article_data.forum_id == 4) {

                forumStore.article_data.forum_name = 'Gossip';

            }

            // 設定 Page Title
            document.title = 'TradingTrain - ' + result.article_data.title;

        } else {
            // 設定 Page Title
            document.title = result.article_data.title + ' - TradingTrain鎚圈';
        }

    }).catch(error => {
        const err = error.response.data;
        ElMessage({
            showClose: true,
            type: "error",
            message: err.Status.Desc,
            duration: 1500
        });
    })

}

export const likeArticle = async () => {

    const { userToken, apiBaseurl } = apiData();
    const forumStore = useForumStore();
    const userStore = useUserStore();

    if (userToken) {
        forumStore.article_data.reaction.likes.number += 1;
        forumStore.article_data.reaction.likes.members.push(userStore.user_id);
    }

    await axios({
        method: 'post',
        url: apiBaseurl + '/forum/article/reaction',
        headers: {
            Authorization: "Bearer " + userToken
        },
        data: {
            article_id: forumStore.article_data.article_id
        }
    }).then(response => {
        const result = response.data;

        ElMessage({
            showClose: true,
            message: result.Status.Desc,
            type: 'success',
            duration: 1000
        });

    }).catch(error => {
        const error_msg = error.response.data;
        ElMessage({
            showClose: true,
            message: error_msg.Status.Desc,
            type: 'warning',
            duration: 1000
        })
    })

}

export const createArticle = async (articleInfo) => {

    const { userToken, apiBaseurl } = apiData();
    const draft_id = window.$cookies.get('draft_id');

    if (draft_id != null) {
        axios({
            method: "post",
            url: apiBaseurl + '/forum/draft/delete',
            headers: {
                Authorization: "Bearer " + userToken
            },
            data: {
                draft_id: parseInt(draft_id)
            }
        }).then(() => {
            window.$cookies.remove('draft_id', '/');
        })
    }

    await axios({
        method: "post",
        url: apiBaseurl + '/forum/article/create',
        headers: {
            Authorization: "Bearer " + userToken
        },
        data: {
            title: articleInfo.title,
            forum_id: parseInt(articleInfo.forum_id),
            html_text: articleInfo.content,
            tags: articleInfo.tags,
            lang: window.$cookies.get("localeSetting")
        }
    }).then(response => {

        const awards_list = response.data.Result.awards_list;
        if (awards_list.length > 0) {
            const commonStore = useCommonStore();
            commonStore.awards.data = awards_list;
            commonStore.awards.toggle = !commonStore.awards.toggle;
        }

        window.onbeforeunload = null;
        if (articleInfo.forum_id == 1) {
            window.location.href = '/forum/announcement';
        } else {
            window.location.href = '/forum';
        }
    })
        .catch(error => {
            const error_msg = error.response.data;
            ElMessage({
                showClose: true,
                message: error_msg.Status.Desc,
                type: 'error',
                duration: 1500
            });
        })

}

export const updateArticle = async (articleInfo) => {

    const { userToken, apiBaseurl } = apiData();

    await axios({
        method: 'post',
        url: apiBaseurl + '/forum/article/update',
        headers: {
            Authorization: "Bearer " + userToken
        },
        data: {
            forum_id: articleInfo.forum_id,
            article_id: articleInfo.article_id,
            title: articleInfo.title,
            html_text: articleInfo.content,
            tags: articleInfo.tags,
            lang: window.$cookies.get("localeSetting")
        }
    }).then(response => {
        const result = response.data;

        ElMessage({
            showClose: true,
            message: result.Status.Desc,
            type: 'success',
            duration: 1500
        });
        window.$cookies.remove('edit_article_id', '/');

    }).catch(error => {
        const error_msg = error.response.data;

        ElMessage({
            showClose: true,
            type: "error",
            message: error_msg.Status.Desc,
            duration: 1500
        });
    })

}

export const deleteArticle = async (article_id) => {

    const { userToken, apiBaseurl } = apiData();

    await ElMessageBox.confirm(
        '刪除後將不可復原。',
        '確定要刪除文章？',
        {
            confirmButtonText: '確認',
            cancelButtonText: '取消',
            confirmButtonClass: 'el-button--coffee',
            type: 'warning',
        }
    ).then(async () => {
        await axios({
            method: 'post',
            url: apiBaseurl + '/forum/article/delete',
            headers: {
                Authorization: "Bearer " + userToken
            },
            data: {
                article_id: article_id
            }
        }).then(response => {

            const result = response.data;

            ElMessage({
                showClose: true,
                message: result.Status.Desc,
                type: 'success',
                duration: 1500
            });

            // if (forum_id != 1) {
            //     window.location.href = '/forum';
            // } else {
            //     this.$router.push({path: '/forum/announcement'})
            // }

        }).catch(error => {

            const error_msg = error.response.data;

            ElMessage({
                showClose: true,
                message: error_msg.Status.Desc,
                type: 'error',
                duration: 1500
            });

        })
    });

}

export const getDraftInfo = async (articleInfo) => {

    const draft_id = window.$cookies.get('draft_id');

    if (draft_id != null) {

        const { userToken, apiBaseurl } = apiData();
        const commonStore = useCommonStore();

        await axios({
            method: 'get',
            url: apiBaseurl + '/forum/draft/info',
            headers: {
                Authorization: "Bearer " + userToken
            },
            params: {
                draft_id: parseInt(draft_id)
            }
        })
            .then(response => {
                var result = response.data.Result;
                console.log(result);

                articleInfo.draft_id = result.draft_id;
                articleInfo.title = result.title;
                articleInfo.content = result.content;
                articleInfo.forum_name = result.forum_name;
                articleInfo.forum_id = result.forum_id;
                articleInfo.tags = result.tags;
                console.log(articleInfo.content);
            })
    }


}

export const upsertDraft = async (articleInfo) => {

    const { userToken, apiBaseurl } = apiData();

    const upsertResult = await axios({
        method: "post",
        url: apiBaseurl + '/forum/draft/upsert',
        headers: {
            Authorization: "Bearer " + userToken
        },
        data: {
            title: articleInfo.title,
            forum_id: articleInfo.forum_id,
            html_text: articleInfo.content,
            tags: articleInfo.tags,
            draft_id: articleInfo.draft_id
        }
    }).then(response => {

        const result = response.data;
        // 怕按到 refresh，因此暫時儲存 draft id 在資料庫
        const draft_id = result.Result.draft_id;
        articleInfo.draft_id = draft_id;
        window.$cookies.set('draft_id', draft_id, "10min", '/');

        ElMessage({
            showClose: true,
            message: result.Status.Desc,
            type: 'success',
            duration: 1500
        });

        return true;

    }).catch(error => {

        const err = error.response.data;
        ElMessage({
            showClose: true,
            message: err.Status.Desc,
            type: 'error',
            duration: 1500
        });

        return false;
    });

    return upsertResult;

}

export const deleteDraft = async (draft_id, draftList) => {

    const { userToken, apiBaseurl } = apiData();

    await ElMessageBox.confirm(
        '是否繼續？',
        '你將刪除文章草稿',
        {
            confirmButtonText: '確認',
            cancelButtonText: '取消',
            confirmButtonClass: 'el-button--coffee'
        }
    ).then(async () => {

        await axios({
            method: "post",
            url: apiBaseurl + '/forum/draft/delete',
            headers: {
                Authorization: "Bearer " + userToken
            },
            data: {
                draft_id: parseInt(draft_id)
            }
        }).then(() => {
            window.$cookies.remove('draft_id', '/');
            draftList = draftList.filter(item => item.draft_id != draft_id);

            ElMessage({
                showClose: true,
                message: '刪除草稿成功',
                type: 'success'
            })
        }).catch(error => {
            const error_msg = error.response.data;

            ElMessage({
                showClose: true,
                message: error_msg.Status.Desc,
                type: 'error'
            })
        })
    });

    return draftList;
}

export const getMentionList = async (search_name, renderList) => {

    const { apiBaseurl } = apiData();

    axios({
        method: 'get',
        url: apiBaseurl + '/search',
        params: {
            type: 2,
            page: 1,
            search_name: search_name,
            mode: "mention"
        }
    }).then(response => {
        const result = response.data.Result.items;
        var items = []
        result.forEach(item => {
            items.push({
                id: item.user_id,
                value: item.username,
                profile_picture: item.profile_picture,
                link: '/user/' + item.user_id.toString()
            });
        })
        renderList(items);

    }).catch(error => {
        const err = error.response.data;
        ElMessage({
            showClose: true,
            type: "error",
            message: err.Status.Desc,
            duration: 1500
        });
    });

}

export const likeComment = async (forum_id, article_id, comment_id, reaction) => {

    const userStore = useUserStore();
    reaction.likes.members.push(userStore.user_id);
    reaction.likes.number += 1;

    const { userToken, apiBaseurl } = apiData();

    await axios({
        method: 'post',
        url: apiBaseurl + '/forum/comment/reaction',
        headers: {
            Authorization: "Bearer " + userToken
        },
        data: {
            article_id: article_id,
            forum_id: forum_id,
            comment_id: comment_id
        }
    }).then(response => {

        const result = response.data;
        ElMessage({
            showClose: true,
            message: result.Status.Desc,
            type: 'success',
            duration: 1000
        });

    }).catch(error => {

        const error_msg = error.response.data;
        ElMessage({
            showClose: true,
            message: error_msg.Status.Desc,
            type: 'warning',
            duration: 1000
        });

    })
}

const dateToFullString = (date) => {

    let year = date.getFullYear();

    let month = date.getMonth() + 1;
    if (month < 10) {
        month = `0${month}`;
    }

    let day = date.getDate();
    if (day < 10) {
        day = `0${day}`;
    }

    let hour = date.getHours();
    if (hour < 10) {
        hour = `0${hour}`
    }

    let minute = date.getMinutes();
    if (minute < 10) {
        minute = `0${minute}`
    }

    let seconds = date.getSeconds();
    if (seconds < 10) {
        seconds = `0${seconds}`
    }

    return `${year}-${month}-${day} ${hour}:${minute}:${seconds}`
    
}

export const createComment = async (article_data, author_data, commentContent, imageFileList) => {

    const userStore = useUserStore();
    const { userToken, apiBaseurl } = apiData();

    await axios({
        method: 'post',
        url: apiBaseurl + '/forum/comment/create',
        headers: {
            Authorization: "Bearer " + userToken
        },
        data: {
            article_id: article_data.article_id,
            author_id: author_data.user_id,
            html_text: commentContent,
            img_list: imageFileList
        }
    })
        .then(response => {

            const result = response.data.Result;

            const createDate = new Date();
            const formatDate = dateToFullString(createDate);

            article_data.comments.push({
                user_id: userStore.user_id,
                comment_id: result.comment_id,
                username: userStore.username,
                profile_picture: userStore.profile_picture,
                content: commentContent,
                Modified_At: formatDate,
                Created_At: formatDate,
                reaction: {
                    likes: {
                        number: 0,
                        members: []
                    }
                },
                img_url_list: result.img_url_list,
                asset_level: userStore.asset_level
            });

            imageFileList = [];
            commentContent = "";

            const awards_list = result.awards_list;
            if (awards_list.length > 0) {
                const commonStore = useCommonStore();
                commonStore.awards.data = awards_list;
                commonStore.awards.toggle = !commonStore.awards.toggle;
            }

        })
        .catch(error => {
            const error_msg = error.response.data;
            ElMessage({
                showClose: true,
                message: error_msg.Status.Desc,
                type: 'error'
            })

            // this.create_loading = false;
        })
}

export const deleteComment = async (article_data, comment_id) => {

    const { userToken, apiBaseurl } = apiData();
    const commonStore = useCommonStore();

    let title = '確定要刪除留言？';
    let desc = '刪除後將不可復原。';
    let confirmBtn = '確認';
    let cancelBtn = '取消';

    if (commonStore.localeSetting == 'en') {
        title = 'Are you sure you want to delete the comment?';
        desc = 'It cannot be recovered after deletion.';
        confirmBtn = 'Confirm';
        cancelBtn = 'Cancel'
    }

    await ElMessageBox.confirm(
        desc,
        title,
        {
            confirmButtonText: confirmBtn,
            cancelButtonText: cancelBtn,
            confirmButtonClass: 'el-button--coffee',
            type: 'warning',
        }
    ).then(async () => {
        await axios({
            method: 'post',
            url: apiBaseurl + '/forum/comment/delete',
            headers: {
                Authorization: "Bearer " + userToken
            },
            data: {
                article_id: article_data.article_id,
                comment_id: comment_id
            }
        }).then(response => {

            const result = response.data;

            article_data.comments = article_data.comments.filter(
                item => item.comment_id != comment_id
            );

            ElMessage({
                showClose: true,
                type: 'success',
                message: result.Status.Desc,
                duration: 1500
            });

        }).catch(error => {
            const error_msg = error.response.data;
            ElMessage({
                showClose: true,
                type: 'success',
                message: error_msg.Status.Desc,
                duration: 1500
            });
        })
    })
}

export const reportComment = async (article_id, reportForm) => {

    const { userToken, apiBaseurl } = apiData();

    await axios({
        method: 'post',
        url: apiBaseurl + '/forum/comment/report',
        headers: {
            Authorization: "Bearer " + userToken
        },
        data: {
            article_id: article_id,
            comment_id: reportForm.comment_id,
            r_type: reportForm.type,
            r_content: reportForm.content
        }
    }).then(response => {
        const result = response.data;

        ElMessage({
            showClose: true,
            message: result.Status.Desc,
            type: 'success',
            duration: 1500
        });

        reportForm.comment_id = "";
        reportForm.type = "1";
        reportForm.content = "";

    }).catch(error => {
        const error_msg = error.response.data;

        ElMessage({
            showClose: true,
            message: error_msg.Status.Desc,
            type: 'error',
            duration: 1500
        });
    })

}