import axios from "axios";
import { apiData } from "./useCommon";
import { ElMessage } from "element-plus";
import { useCommonStore, useMissionStore } from "@/store";

export const getChapterInfo = async () => {

    const { userToken, apiBaseurl } = apiData();
    const store = useMissionStore();

    await axios({
        method: "get",
        url: apiBaseurl + '/mission/chapter/info',
        headers: {
            Authorization: "Bearer " + userToken
        }
    }).then(response => {
        var result = response.data;
        store.chapter_info = result['Result'];
    }).catch(error => {
        var err = error.response.data;

        ElMessage({
            showClose: true,
            type: 'error',
            message: err.Status.Desc,
            duration: 1500
        });
    });
}

export const getLevelsInfo = async (chapter_id) => {

    const { userToken, apiBaseurl } = apiData();
    const missionStore = useMissionStore();
    const commonStore = useCommonStore();

    // if (!["1", "2"].includes(chapter_id)) {
    //     window.location.href = '/mission/chapter';
    // }

    await axios({
        method: "get",
        url: apiBaseurl + '/mission/level/info',
        headers: {
            Authorization: "Bearer " + userToken
        },
        params: {
            chapter: chapter_id
        }
    }).then(response => {
        var result = response.data.Result;

        if (!result.chapter_enabled) {
            window.location.href = '/mission/chapter';
        } else {
            missionStore.levels = result.levels;
        }

    })
};

export const getMissionDesc = async (chapter_id, level_id) => {

    const { userToken, apiBaseurl } = apiData();

    await axios({
        method: "get",
        url: apiBaseurl + "/mission/level/desc",
        headers: {
            Authorization: "Bearer " + userToken
        },
        params: {
            chapter: chapter_id,
            level: level_id
        }
    }).then(response => {

        const store = useMissionStore();

        if (chapter_id == 1) {
            store.chapter_name = '第一章：抄底'
        } else if (chapter_id == 2) {
            store.chapter_name = '第二章：追價'
        } else if (chapter_id == 3) {
            store.chapter_name = '第三章：摸頂'
        } else if (chapter_id == 4) {
            store.chapter_name = '第四章：追空'
        }

        const levelDesc = response.data.Result;
        store.level_desc = levelDesc;

    }).catch(error => {
        const error_msg = error.response.data;

        ElMessage({
            showClose: true,
            message: error_msg.Status.Desc,
            type: 'error',
            duration: 1500
        });

    })
}

export const createMission = async (chapter_id, level_id, lang='zh-TW') => {

    const { userToken, apiBaseurl } = apiData();

    axios({
        method: "post",
        url: apiBaseurl + '/mission/level/game/create',
        headers: {
            Authorization: "Bearer " + userToken
        },
        data: {
            chapter: chapter_id,
            level: level_id,
            lang: lang
        }
    }).then(response => {

        const store = useMissionStore();

        const result = response.data.Result;
        store.game_data = result.game_data;
        store.game_mode = result.game_mode;

    }).catch(error => {
        const error_msg = error.response.data;

        // 無權限遊玩此關卡
        if (error_msg.Status.Code == 401) {
            window.onbeforeunload = null;
            window.location.href = '/mission/chapter/'+chapter_id.toString();
        }
        
        ElMessage({
            showClose: true,
            type: "error",
            message: error_msg.Status.Desc,
            duration: 1500
        });
    })

}

export const deleteMission = async () => {

    const { userToken, apiBaseurl } = apiData();

    await axios({
        method: 'post',
        url: apiBaseurl + '/mission/level/game/delete',
        headers: {
            Authorization: "Bearer " + userToken
        }
    }).catch(error => {
        const error_msg = error.response.data;
        ElMessage({
            showClose: true,
            type: "error",
            message: error_msg.Status.Desc,
            duration: 1500
        });
    })

}

export const endMission = async () => {

    const { userToken, apiBaseurl } = apiData();
    const store = useMissionStore();

    await axios({
        method: "get",
        url: apiBaseurl + "/mission/level/game/result",
        headers: {
            Authorization: "Bearer " + userToken
        }
    })
    .then(response => {
        const game_result = response.data.Result;
        if (game_result != null) {
            store.game_result = game_result;
        } else {
            window.location.href = '/';
        }
    })
}