import { defineStore } from "pinia";

export const useCommonStore = defineStore('common', {
    state: () => ({
        // apiBaseurl: "http://127.0.0.1:3000/v2",
        apiBaseurl: "https://test-api.tdtn.tw/v2",
        // apiBaseurl: 'https://api.tdtn.tw/v2',
        localeSetting: '',
        windowPath: location.pathname,
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
        headerMember: !1,
        headerSearch: !1,
        headerBell: false,
        navbarShow: false,
        search: {
            name: null,
            type: 1,
            result: [],
            total_items: 0
        },
        awards: {
            toggle: false,
            data: []
        },
        isXnft: null,
        // genesisCollectionAddress: "7SJiu2jPMKddCHDBKkwR7rQrKum33GGyvoCdjEgwFVsR",
        genesisCollectionAddress: "DUfmAvCEPGau1Rf8U62ZFXKogXYLa1BoEgsYSggy1x9f",
        // solanaNetwork: "devnet"
        solanaNetwork: "mainnet-beta"
    })
});

export const useUserStore = defineStore('user', {
    state: () => ({
        user_id: null,
        username: '',
        rank: null,
        asset_level: 0,
        total_asset: 0,
        convert_asset: "",
        portfolio: 0,
        convert_portfolio: "",
        profile_picture: "",
        in_game: false,
        level: 0,
        tt_coin: 0,
        exp: 0,
        isLogin: false,
        isWalletConnected: false,
        xNftAddress: "",
        isGenesis: false,
        referral_code: "",
        self_content: "",
        social_link: {
            facebook: "",
            line: "",
            instagram: ""
        },
        score_board: {
            avg_return: 0,
            win_count: 0,
            game_count: 0,
            win_rate: 0.0,
            last_10: '0/0/0'
        },
        awards: {
            total_items: 0,
            unlock_items: 0,
            data: []
        },
        article_data: {
            Draft: [],
            Latest_Post: [],
            Reply: []
        },
        selfInfo: {
            form1: {
                nickname: "",
                first_name: "",
                last_name: "",
                instagram: "",
                facebook: "",
                line: "",
                city: "",
                district: "",
                address: "",
                cellphone: ""
            },
            form2: {
                gender: "",
                marriage: "",
                child_num: "",
                age: "",
                birthday: "",
                educate: "",
                job: "",
                grade: ""
            },
            form3: {
                personal_avg_year_income: "",
                personal_monthly_cost: "",
                invest_budget: "",
                family_monthly_income: "",
                family_key_man: "",
                current_live_house: "",
                current_invest_house: "",
                current_family_cars: "",
                monthly_domestic_travel: "",
                yearly_foreign_travel: ""
            }
        }
    })
});

export const useGamerStore = defineStore('gamer', {
    state: () => ({
        user_id: null,
        username: '',
        rank: null,
        asset_level: 0,
        total_asset: 0,
        convert_asset: "",
        profile_picture: "",
        level: 0,
        self_content: "",
        social_link: {
            facebook: "",
            line: "",
            instagram: ""
        },
        score_board: {
            avg_return: 0,
            win_count: 0,
            game_count: 0,
            win_rate: 0.0,
            last_10: '0/0/0'
        },
        awards: {
            data: []
        },
        article_data: {
            Latest_Post: [],
            Reply: []
        }
    })
});

export const useHomePageStore = defineStore('homePage', {
    state: () => ({
        article_data: {},
        rank_data: []
    })
});

export const useMissionStore = defineStore('mission', {
    state: () => ({
        chapter_info: [],
        chapter_name: '',
        levels: [],
        level_desc: {},
        game_mode: "",
        game_data: {},
        game_result: {},
        loading: null
    })
});

export const useForumStore = defineStore('forum', {
    state: () => ({
        forum_id: null,
        total_items: 0,
        article_items: [],
        article_data: {}, // 單篇文章內容
        author_data: {}, // 單篇文章作者
    })
});

export const useRankStore = defineStore('rank', {
    state: () => ({
        type: "returns",
        period: "total",
        pageQty: 10,
        search_name: null,
        my_rank_data: {},
        rank_data: [],
        total_items: 0
    })
});

export const useHistoryStore = defineStore('history', {
    state: () => ({
        sort_col: "Game_Date",
        sort_type: -1,
        pageQty: 10,
        total_items: 0,
        search_name: "",
        history_records: [],
        assetsChartOptions: { // 資產走勢圖設定
            credits: {
                enabled: false,
            },
            chart: {
                backgroundColor: 'transparent',
                marginTop: 40,
                marginRight: 30
            },
            title: {
                text: ''
            },
            yAxis: {
                title: {
                    text: '累積資產',
                    style: {
                        color: 'black'
                    }
                },
                labels: {
                    style: {
                        color: 'black'
                    }
                },
                gridLineColor: 'rgba(106, 60, 28, 0.5)'
            },

            xAxis: {
                visible: false
            },
            tooltip: {
                formatter: function () {
                    return '<b>Game Id：' + this.point.description.Game_Id + '</b><br>' +
                        '<p>投資標的：' + this.point.description.stock_id + ' ' +
                        this.point.description.stock_name + '</p><br>' +
                        '<p>累積資產：' + this.point.description.convert_asset + '</p>';
                },
                style: {
                    fontSize: 14,
                }
            },
            series: [{
                type: 'line',
                name: '歷史持有資產',
                data: null,
                turboThreshold: 20000,
                color: 'black'
            }],
            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    point: {
                        events: {
                            click: function () {
                                window.open('/game_detail?game_id=' + this.options.description.Game_Id, '_blank');
                            }
                        }
                    },
                    cursor: "pointer"
                }
            }
        },
        returnChartOptions: { // 報酬率走勢圖設定
            credits: {
                enabled: false,
            },
            chart: {
                backgroundColor: 'transparent',
                marginTop: 40,
                marginRight: 30
            },
            title: {
                text: ''
            },
            yAxis: {
                title: {
                    text: '報酬率（%）',
                    style: {
                        color: 'black'
                    }
                },
                labels: {
                    style: {
                        color: 'black'
                    }
                },
                gridLineColor: 'rgba(106, 60, 28, 0.5)'
            },
            xAxis: {
                visible: false
            },
            tooltip: {
                formatter: function () {
                    return '<b>Game Id：' + this.point.description.Game_Id + '</b><br>' +
                        '<p>投資標的：' + this.point.description.stock_id + ' ' +
                        this.point.description.stock_name + '</p><br>' +
                        '<p>報酬率：' + this.y + ' %</p><br>';
                },
                style: {
                    fontSize: 14,
                }
            },
            series: [{
                type: 'line',
                name: '歷史戰績報酬率',
                data: null,
                turboThreshold: 20000,
                color: 'black'
            }],
            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    point: {
                        events: {
                            click: function () {
                                window.open('/game_detail?game_id=' + this.options.description.Game_Id, '_blank');
                            }
                        }
                    },
                    cursor: "pointer"
                }
            }
        }
    })
});

export const useGameStore = defineStore('game', {
    state: () => ({
        game_data: {},
        game_mode: "",
        game_params: {},
        game_result: {},
        game_permission: {},
        loading: null
    })
});

export const useNotifyStore = defineStore('notify', {
    state: () => ({
        notifications: "0",
        notifyHeaderData: [],
        notifyHistoryData: [],
        total_items: 0,
        ifBonkTime: false
    })
});

export const useFriendStore = defineStore('friend', {
    state: () => ({
        friends: [],
        blacklist: [],
        invite: [],
        friend_code: 0,
        isSub: false
    })
});

export const useMailStore = defineStore('mail', {
    state: () => ({
        send_data: {
            user_id: 0,
            title: '',
            content: ''
        },
        select_value: '',
        email_data: [],
        total_items: 0,
        email_detail: {}
    })
});

export const useSubscribeStore = defineStore('subscribe', {
    state: () => ({
        followers: [],
        following: []
    })
});

export const useCompetitionStore = defineStore('competition', {
    state: () => ({
        list: [],
        user_info: {},
        rank_data: [],
        pageQty: 10,
        total_items: 0
    })
});

export const useRewardsStore = defineStore('rewards', {
    state: () => ({
        rewards_info: {},
        game_result: {}
    })
});

export const useShareStore = defineStore('share', {
    state: () => ({
        pageQty: 10,
        reward_list_data: [],
        total_items: 0
    })
});