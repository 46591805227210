import axios from "axios";
import { apiData } from "./useCommon";
import { ElMessage } from "element-plus";
import { useRewardsStore } from "@/store";

export const getUserRewards = async () => {

    const { apiBaseurl, userToken } = apiData();

    await axios({
        method: "get",
        url: apiBaseurl + "/rewards/info",
        headers: {
            Authorization: "Bearer "+ userToken
        }
    }).then(response => {

        const store = useRewardsStore();
        store.rewards_info = response.data.Result;

    }).catch(error => {

        const error_msg = error.response.data;
        ElMessage({
            showClose: true,
            type: "error",
            message: error_msg.Status.Desc,
            duration: 1500
        });

    })

}

export const claimUserRewards = async (name) => {

    const { apiBaseurl, userToken } = apiData();

    await axios({
        method: "post",
        url: apiBaseurl + "/rewards/claim",
        headers: {
            Authorization: "Bearer "+ userToken
        },
        data: {
            name: name
        }
    }).then(() => {

        ElMessage({
            showClose: true,
            type: "success",
            message: 'Claim Tokens successful.',
            duration: 1500
        });

    }).catch(error => {

        const error_msg = error.response.data;
        ElMessage({
            showClose: true,
            type: "error",
            message: error_msg.Status.Desc,
            duration: 1500
        });

    })

}

export const getGameRewards = async (game_id) => {

    const { apiBaseurl } = apiData();

    await axios({
        method: "get",
        url: apiBaseurl + "/game/rewards",
        params: {
            game_id: game_id,
            lang: window.$cookies.get("localeSetting")
        }
    }).then(response => {

        const store = useRewardsStore();
        store.game_result = response.data.Result;

    }).catch(error => {

        const error_msg = error.response.data;
        ElMessage({
            showClose: true,
            type: "error",
            message: error_msg.Status.Desc,
            duration: 1500
        });

        window.location.href = '/';

    })

}