import axios from "axios";
import { useUserStore, useHistoryStore, useCommonStore } from "@/store";
import { apiData } from "./useCommon";
import { ElMessage, ElMessageBox } from "element-plus";

export const getUserBasic = async () => {

    const userStore = useUserStore();
    // const commonStore = useCommonStore();
    const { userToken, apiBaseurl } = apiData();

    await axios({
        method: "get",
        url: apiBaseurl + '/user/basic',
        params: {
            lang: window.$cookies.get('localeSetting')
        },
        headers: {
            Authorization: "Bearer " + userToken
        }
    }).then(response => {
        
        var result = response.data;
        var user_data = result.Result.user_data;

        userStore.user_id = user_data.user_id;
        userStore.username = user_data.username;
        userStore.profile_picture = user_data.profile_picture;
        userStore.rank = user_data.rank;
        userStore.asset_level = user_data.asset_level;
        userStore.total_asset = user_data.total_asset;
        userStore.convert_asset = user_data.convert_asset;
        userStore.portfolio = user_data.portfolio;
        userStore.convert_portfolio = user_data.convert_portfolio;
        userStore.in_game = user_data.in_game;
        userStore.level = user_data.level;
        userStore.tt_coin = user_data.tt_coin;
        userStore.referral_code = user_data.referral_code;

    })

}

export const getUserInfo = async () => {

    const userStore = useUserStore();
    const commonStore = useCommonStore();
    const { userToken, apiBaseurl } = apiData();

    await axios({
        method: "get",
        url: apiBaseurl + '/user/info',
        params: {
            lang: commonStore.localeSetting
        },
        headers: {
            Authorization: "Bearer " + userToken
        }
    }).then(response => {

        const result = response.data.Result;
        console.log(result);

        const user_data = result.user_data;
        userStore.self_content = user_data.self_content;
        userStore.exp = user_data.user_exp;

        // 戰績相關資料
        userStore.score_board.avg_return = user_data.avg_return;
        userStore.score_board.game_count = user_data.game_count;
        userStore.score_board.last_10 = user_data.last_10;
        userStore.score_board.win_count = user_data.win_count;
        userStore.score_board.win_rate = user_data.win_rate;

        // 社群連結資料
        userStore.social_link.facebook = user_data.user_social_link.facebook;
        userStore.social_link.instagram = user_data.user_social_link.instagram;
        userStore.social_link.line = user_data.user_social_link.line;

        // 獎盃相關資料
        userStore.awards.total_items = user_data.awards.length;
        userStore.awards.unlock_items = user_data.awards.filter(
            item => item.unlock == true
        ).length;
        userStore.awards.data = user_data.awards;

        // 文章相關資料
        const article_data = result.article_data;
        userStore.article_data.Draft = article_data.Draft;
        userStore.article_data.Latest_Post = article_data.Latest_Post;
        userStore.article_data.Reply = article_data.Reply;
    })

}

export const getUserDetail = async () => {

    const store = useUserStore();
    const { userToken, apiBaseurl } = apiData();

    await axios({
        method: "get",
        url: apiBaseurl + '/user/detail',
        headers: {
            Authorization: "Bearer " + userToken
        }
    }).then(response => {
        const result = response.data.Result;
        const user_info = result.user_info;

        // 基本資料
        store.selfInfo.form1.nickname = result.name.nickname;
        store.selfInfo.form1.username = result.name.username;
        store.selfInfo.form1.first_name = result.name.first_name;
        store.selfInfo.form1.last_name = result.name.last_name;

        store.selfInfo.form1.instagram = result.user_social_link.instagram;
        store.selfInfo.form1.facebook = result.user_social_link.facebook;
        store.selfInfo.form1.line = result.user_social_link.line;

        store.selfInfo.form1.city = user_info.city;
        store.selfInfo.form1.district = user_info.district;
        store.selfInfo.form1.address = user_info.address;
        store.selfInfo.form1.cellphone = user_info.cellphone;

        // 詳細資料
        store.selfInfo.form2.gender = user_info.gender;
        store.selfInfo.form2.marriage = user_info.marriage;
        store.selfInfo.form2.child_num = user_info.child_num;
        store.selfInfo.form2.age = user_info.age;
        store.selfInfo.form2.birthday = user_info.birthday;
        store.selfInfo.form2.educate = user_info.educate;
        store.selfInfo.form2.job = user_info.job;
        store.selfInfo.form2.grade = user_info.grade;

        // 投資資料 
        store.selfInfo.form3.personal_avg_year_income = user_info.personal_avg_year_income;
        store.selfInfo.form3.personal_monthly_cost = user_info.personal_monthly_cost;
        store.selfInfo.form3.invest_budget = user_info.invest_budget;
        store.selfInfo.form3.family_monthly_income = user_info.family_monthly_income;
        store.selfInfo.form3.family_key_man = user_info.family_key_man;
        store.selfInfo.form3.current_live_house = user_info.current_live_house;
        store.selfInfo.form3.current_invest_house = user_info.current_invest_house;
        store.selfInfo.form3.current_family_cars = user_info.current_family_cars;
        store.selfInfo.form3.monthly_domestic_travel = user_info.monthly_domestic_travel;
        store.selfInfo.form3.yearly_foreign_travel = user_info.yearly_foreign_travel;
    });

}

export const saveUserDetail = async () => {

    const commonStore = useCommonStore();
    const localeSetting = commonStore.localeSetting;

    let title = '更新資料';
    let content = '您將更新個人資料，是否繼續？';
    let comfirmBtn = '確認';
    let cancelBtn = '取消';

    if (localeSetting == 'en') {
        title = 'Update Info';
        content = 'You are about to update your profile, do you want to continue?';
        comfirmBtn = 'Confirm';
        cancelBtn = 'Cancel';
    }

    await ElMessageBox.confirm(
        content,
        title,
        {
            confirmButtonText: comfirmBtn,
            cancelButtonText: cancelBtn,
            confirmButtonClass: 'el-button--coffee',
            type: 'warning'
        }
    ).then(async () => {

        const store = useUserStore();
        const { userToken, apiBaseurl } = apiData();

        await axios({
            method: 'post',
            url: apiBaseurl + '/user/detail/update',
            headers: {
                Authorization: "Bearer " + userToken
            },
            data: {
                basic_info: store.selfInfo.form1,
                detail_info: store.selfInfo.form2,
                invest_info: store.selfInfo.form3
            }
        }).then(response => {
            const result = response.data;

            const awards_list = result.Result.awards_list;
            if (awards_list.length > 0) {
                const commonStore = useCommonStore();
                commonStore.awards.toggle = !commonStore.awards.toggle;
                commonStore.awards.data = awards_list;
            }

            ElMessage({
                showClose: true,
                message: result.Status.Desc,
                type: 'success',
                duration: 1500
            });
        }).catch(error => {
            const error_msg = error.response.data;
            ElMessage({
                showClose: true,
                message: error_msg.Status.Desc,
                type: 'error',
                duration: 1500
            });
        })

    })

}

export const userHistoryRecords = async (page) => {

    const historyStore = useHistoryStore();
    const { userToken, apiBaseurl } = apiData();

    await axios({
        method: "post",
        url: apiBaseurl + '/user/game_history',
        headers: {
            Authorization: "Bearer " + userToken
        },
        data: {
            page: page,
            limit: historyStore.pageQty,
            sort_col: historyStore.sort_col,
            sort_type: historyStore.sort_type,
            search_name: historyStore.search_name,
            lang: window.$cookies.get("localeSetting")
        }
    }).then(response => {

        var result = response.data.Result;
        historyStore.history_records = result.items;
        historyStore.total_items = result.total_items;
        document.getElementById("records-top").scrollIntoView();

    }).catch(error => {
        var err = error.response.data;
        ElMessage({
            showClose: true,
            type: "error",
            message: err.Status.Desc,
            duration: 1500
        });
    })

}

export const userHistoryAssets = async (limit) => {

    const historyStore = useHistoryStore();
    const { userToken, apiBaseurl } = apiData();

    let num;
    if (limit == "all") {
        num = null
    } else {
        num = parseInt(limit)
    }

    await axios({
        method: "get",
        url: apiBaseurl + '/user/asset_history',
        headers: {
            Authorization: "Bearer " + userToken
        },
        params: {
            limit: num
        }
    }).then(response => {

        var result = response.data.Result;
        historyStore.assetsChartOptions.series[0].data = result;

    }).catch(error => {
        var err = error.response.data;
        ElMessage({
            showClose: true,
            type: "error",
            message: err.Status.Desc,
            duration: 1500
        })
    });
}

export const userHistoryReturn = async (limit) => {

    const historyStore = useHistoryStore();
    const { userToken, apiBaseurl } = apiData();

    let num;
    if (limit == "all") {
        num = null
    } else {
        num = parseInt(limit)
    }

    await axios({
        method: "get",
        url: apiBaseurl + '/user/return_history',
        headers: {
            Authorization: "Bearer " + userToken
        },
        params: {
            limit: num
        }
    }).then(response => {
        var result = response.data.Result;
        historyStore.returnChartOptions.series[0].data = result;
    })

}

export const updateSelfIntro = async (self_content) => {

    const { userToken, apiBaseurl } = apiData();
    axios({
        method: 'post',
        url: apiBaseurl + '/user/self_content/update',
        headers: {
            Authorization: 'Bearer ' + userToken
        },
        data: {
            html_content: self_content
        }
    }).then(response => {

        console.log(response);
        ElMessage({
            showClose: true,
            type: 'success',
            message: '修改自我介紹成功',
            duration: 1500
        });

    }).catch((error) => {
        var err = error.response.data;
        if (err.Status.code == 401) {
            window.location.reload();
        } else {
            ElMessage({
                showClose: true,
                type: 'success',
                message: err.Status.Desc,
                duration: 1500
            });
        }
    })

}