import axios from "axios";
import { apiData } from "./useCommon";
import { useCommonStore, useRankStore, useUserStore } from "@/store";
import { ElMessage } from "element-plus";

export const getRankData = async (page) => {

    const { userToken, apiBaseurl } = apiData();

    const userStore = useUserStore();

    const rankStore = useRankStore();
    const nPerpage = rankStore.pageQty;
    const type_ = rankStore.type;
    const period = rankStore.period;
    const search_name = rankStore.search_name;

    const commonStore = useCommonStore();

    await axios({
        method: "get",
        url: apiBaseurl + "/rank/detail",
        headers: {
            Authorization: "Bearer "+ userToken
        },
        params: {
            page: page,
            nPerpage: nPerpage,
            type_: type_,
            period: period,
            search_name: search_name,
            lang: commonStore.localeSetting
        }
    }).then(response => {
        const result = response.data.Result;

        if (userToken) {

            const my_rank_data = result.items.filter(
                elem => elem.user_id == userStore.user_id
            )[0];

            rankStore.my_rank_data = my_rank_data;

            rankStore.rank_data = result.items.filter(
                elem => elem.user_id != userStore.user_id
            );

        } else {
            rankStore.rank_data = result.items; 
            if (!userToken) {
                rankStore.my_rank_data = null;
            }   
        }
        rankStore.total_items = result.total_items;

    }).catch(error => {
        const err = error.response.data;
        ElMessage({
            showClose: true,
            type: 'error',
            message: err.Status.Desc,
            duration: 1500
        });
    })

}