<template>
  <router-view />
</template>

<script>
import { initWallet } from 'solana-wallets-vue';
import { initWorkspace, initMetaplex, checkLogin, facebookInit } from '@/composables';
import {
  BackpackWalletAdapter,
  PhantomWalletAdapter,
  GlowWalletAdapter
} from "@solana/wallet-adapter-wallets";
import { ref } from 'vue';

export default {
  name: 'appPage',
  setup() {
    checkLogin();

    const autoConnect = ref(false);
    if (window.$cookies.isKey('autoConnect')) {
      if (window.$cookies.get('autoConnect') == "true") {
        autoConnect.value = true;
      } else {
        autoConnect.value = false;
      }
    }

    const walletOptions = {
      wallets: [
        new BackpackWalletAdapter(),
        new PhantomWalletAdapter(),
        new GlowWalletAdapter()
      ],
      autoConnect: autoConnect.value
    };
    initWallet(walletOptions);
    initWorkspace();
    initMetaplex();
    facebookInit();
  }
}
</script>

<style>
@import url("./assets/css/style.css");
/* 自行調整 global css，由於跟廠商合作，不方便直接改 style.css */
@import url("./assets/css/modify.css");
/* 排版相關 */
@import url("./assets/css/display.css");
/* 按鈕相關 */
@import url("./assets/css/button.css");
/* quill 編輯器 */
@import url("./assets/css/editor.css");
/* highcharts popup */
@import url("./assets/css/gui.css");
@import url("./assets/css/popup.css");
</style>
