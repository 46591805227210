<template>

    <common-modal ref="loginModal" :can-close="true" :tit="$t('modal.login.tit')" class="md" v-if="localeSetting == 'zh-TW'">
        <template v-slot:infoArea>
            <div class="btnArea" v-if="loginType.google" style="margin-top: 15px;">
                <div class="btn google">
                    <a href="javascript:void(0);" title="使用Google登入" @click="googleLogin">
                        <picture>
                            <source media="(max-width: 991px)" srcset="../../assets/images/icon_google@2.png" />
                            <img src="../../assets/images/icon_google.png" alt="使用Google登入" />
                        </picture>
                        {{ $t('modal.login.thirdPartyBtn.google') }}
                    </a>
                </div>
            </div>
            <div class="btnArea margin-bottom-0" v-if="loginType.facebook" :style="!loginType.google ? 'margin-top: 15px;' : ''">
                <div class="btn google margin-bottom-0">
                    <a href="javascript:void(0);" title="使用Facebook登入" @click="facebookLogin"
                        style="background:linear-gradient(120deg, #FFFFFF, #3b5998);">
                        <picture>
                            <source media="(max-width: 991px)" srcset="../../assets/images/fb_logo.png" />
                            <img src="../../assets/images/fb_logo.png" alt="使用Facebook登入" />
                        </picture>
                        {{ $t('modal.login.thirdPartyBtn.facebook') }}
                    </a>
                </div>
            </div>
            <div class="btnArea margin-bottom-0" v-if="loginType.line" :style="!loginType.google && !loginType.facebook ? 'margin-top: 15px;' : ''">
                <div class="btn google margin-bottom-0">
                    <a href="javascript:void(0);" title="使用Line登入" @click="lineRedirect"
                        style="background:linear-gradient(120deg, #FFFFFF, rgb(25,185,2));">
                        <picture>
                            <source media="(max-width: 991px)" srcset="../../assets/images/line_logo.png" />
                            <img src="../../assets/images/line_logo.png" alt="使用Line登入" />
                        </picture>
                        {{ $t('modal.login.thirdPartyBtn.line') }}
                    </a>
                </div>
            </div>
            <div class="btnArea margin-bottom-0" v-if="loginType.discord" :style="!loginType.google && !loginType.facebook && !loginType.line ? 'margin-top: 15px;' : ''">
                <div class="btn google margin-bottom-0">
                    <a href="javascript:void(0);" title="使用 Discord 登入" @click="discordRedirect"
                        style="background: linear-gradient(120deg, #FFFFFF, #404EED)">
                        <picture>
                            <source media="(max-width: 991px)" srcset="../../assets/images/discord_login_logo.png" />
                            <img src="../../assets/images/discord_login_logo.png" alt="使用 Discord 登入" />
                        </picture>
                        {{ $t('modal.login.thirdPartyBtn.discord') }}
                    </a>
                </div>
            </div>
            <el-divider class="type3" style="margin: 12px 0px;"></el-divider>
            <el-form label-position="top" id="login-form">
                <el-form-item :label="$t('modal.login.general.email.label')">
                    <el-input v-model="loginInput.account" type="email" :placeholder="$t('modal.login.general.email.placeholder')"></el-input>
                </el-form-item>
                <el-form-item :label="$t('modal.login.general.password.label')">
                    <el-input v-model="loginInput.password" type="password" show-password :placeholder="$t('modal.login.general.password.placeholder')"></el-input>
                </el-form-item>
                <el-button type="coffee" class="blockBtn margin-bottom-10 login-btn"
                    @click="normalLogin(loginInput.account, loginInput.password)">{{ $t('modal.login.loginBtn') }}
                </el-button>
            </el-form>
            <el-row :gutter="20" class="margin-bottom-15">
                <!-- <el-col :sm="12" :xs="24">
                </el-col> -->
                <el-col class="login-linkArea">
                    <el-link href='/forgot_password'> {{ $t('modal.login.forgotPwd') }} </el-link>
                </el-col>
            </el-row>
        </template>
    </common-modal>

    <common-modal ref="LoginPopup" :can-close=" true " tit="Connect Account" class="md" v-if="localeSetting == 'en'">
        <template v-slot:infoArea>
            <div class="margin-top-20 margin-bottom-20" style="text-align: center;">
                <el-button class="connect-btn" @click="discordRedirect">
                    <img style="margin-right: 10px;" src="../../assets/images/discord_logo.png" width="22">
                    <span>Connect With Discord</span>
                </el-button>
            </div>
        </template>
    </common-modal>

</template>

<script>
import commonModal from '../CommonModal.vue';
import {
    useWorkspace,
    normalLogin,
    googleLogin,
    facebookLogin,
    lineRedirect,
    discordRedirect
} from '@/composables';
import { computed, reactive, onMounted } from "vue";
import { useCommonStore } from '@/store';

export default {
    name: 'loginModal',
    components: {
        commonModal
    },
    props: {
        toggle: {
            default: false,
            required: true
        }
    },
    setup() {
        // 使用者登入之帳號密碼
        const loginInput = reactive({
            account: "",
            password: ""
        });

        // 可使用第三方登入模式
        const loginType = reactive({
            google: true,
            facebook: true,
            line: true,
            discord: true
        });

        const detectBrowser = () => {

            const u = navigator.userAgent;

            if (/phantom/i.test(u)) {
                loginType.google = false;
                loginType.facebook = false;
                loginType.line = false;
            }

            if (u.indexOf("Line") > -1) {
                loginType.google = false;
                loginType.facebook = false;
                loginType.discord = false;
            }

            if (u.indexOf("FBAV") > -1 || u.indexOf("FBAN") > -1) {
                loginType.google = false;
                loginType.line = false;
                loginType.discord = false;
            }
        }

        const commonStore = useCommonStore();
        const localeSetting = computed(() => commonStore.localeSetting);

        // 取得錢包及錢包地址
        const { wallet } = useWorkspace();
        const walletAddress = computed(() => wallet.publicKey.value?.toString());

        onMounted(() => {
            detectBrowser();
        })

        return {
            localeSetting,
            loginInput,
            loginType,
            walletAddress,
            normalLogin,
            googleLogin,
            facebookLogin,
            lineRedirect,
            discordRedirect
        }
    },
    watch: {
        toggle: function() {
            if (this.localeSetting == 'zh-TW') {
                this.toggleModal('loginModal');
            } else {
                this.toggleModal('LoginPopup');
            }
        }
    },
    methods: {
        toggleModal: function (t) {
            this.$refs[t].toggle = !this.$refs[t].toggle;
        },
    }
}

</script>

<style>
.connect-btn, 
.connect-btn:focus, 
.connect-btn:hover {
    width: 100%!important;
}

@media screen and (max-width: 767px) {
    .btnArea {
        margin: 0px;
    }
    .el-form--label-top .el-form-item .el-form-item__label {
        margin-bottom: 0px!important;
    }
    .login-linkArea {
        padding-top: 0px;
        padding-bottom: 0px;
    }
}
</style>