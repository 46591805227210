import { useCommonStore, useUserStore } from "@/store";
import axios from "axios";
import { ElLoading, ElMessage } from "element-plus";
import { googleTokenLogin } from "vue3-google-login";
import { useWallet } from "solana-wallets-vue";
import { apiData } from "./useCommon";

export const checkLogin = () => {
    const store = useUserStore();

    if (window.$cookies.isKey("userToken")) {
        store.isLogin = true;
    } else {
        store.isLogin = false;
    }
}

export const connectWallet = async () => {

    const wallet = useWallet();
    const walletName = wallet.wallet.value.adapter.name;

    const userStore = useUserStore();
    const { userToken, apiBaseurl } = apiData();

    await axios({
        method: "post",
        url: apiBaseurl + '/connect_wallet',
        headers: {
            Authorization: "Bearer " + userToken
        },
        data: {
            wallet_address: wallet.publicKey.value.toBase58()
        }
    }).then(async response => {

        var result = response.data.Result;

        if (result) {
            userStore.isWalletConnected = true;
        } else {
            if (walletName == 'Phantom') {
                await window.phantom.solana.disconnect();
            } else if (walletName == 'Backpack') {
                await window.backpack.disconnect();
            } else if (walletName == 'Glow') {
                const disConnectBtn = document.getElementsByClassName('swv-dropdown-list-item')[2];
                await disConnectBtn.click();
            }
        }

    }).catch(async (error) => {

        const error_data = error.response.data;
        ElMessage({
            showClose: true,
            message: error_data.Status.Desc,
            type: 'error',
            duration: 2000
        });
        if (walletName == 'Phantom') {
            await window.phantom.solana.disconnect();
        } else if (walletName == 'Backpack') {
            await window.backpack.disconnect();
        } else if (walletName == 'Glow') {
            const disConnectBtn = document.getElementsByClassName('swv-dropdown-list-item')[2];
            await disConnectBtn.click();
        }
    })
}

export const loginLog = async () => {
    
    const { userToken, apiBaseurl } = apiData();

    await axios({
        method: 'post',
        url: apiBaseurl + '/login/log',
        headers: {
            Authorization: 'Bearer '+ userToken
        },
        data: {
            lang: window.$cookies.get('localeSetting')
        }
    });

}

export const normalLogin = async (account, password) => {
    console.log('normal login');

    const { apiBaseurl } = apiData();

    await axios({
        method: 'post',
        url: apiBaseurl + '/login',
        data: {
            account: account,
            password: password,
            mode: 'normal'
        }
    }).then(response => {

        var login_response = response.data;
        var access_token = login_response.Result.access_token

        // Token Expire After 24 hrs
        window.$cookies.set('userToken', access_token, "1d", '/');
        window.location.reload();

    }).catch(error => {

        var error_data = error.response.data;
        ElMessage({
            showClose: true,
            message: error_data.Status.Desc,
            type: 'error',
            duration: 1500
        });

    });
}

export const googleLogin = () => {
    console.log('google login');

    const { apiBaseurl } = apiData();

    googleTokenLogin().then(async (response) => {

        const access_token = response.access_token;
        await axios({
            method: 'post',
            url: apiBaseurl + "/login",
            data: {
                id_token: access_token,
                mode: "google",
                referral_code: window.$cookies.get("referralCode")
            }
        }).then(response => {

            var login_response = response.data;
            var access_token = login_response.Result.access_token
            // Token Expire After 24 hrs
            window.$cookies.set('userToken', access_token, "1d", '/');
            window.location.reload();

        }).catch(error => {

            var error_data = error.response.data;

            ElMessage({
                showClose: true,
                type: 'error',
                message: error_data.Status.Desc,
                duration: 1500
            });
        })
    });
}

export const facebookInit = () => {
    // 防止重複載入
    if (!window.FB) {
        window.fbAsyncInit = function () {
            window.FB.init({
                appId: "495670315651619", // 填入自己 app 的 id
                cookie: true,
                xfbml: true,
                version: "v14.0" // 目前版本
            });
        };

        (function (d, s, id) {
            var js,
                fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        })(document, "script", "facebook-jssdk");
    }
}

export const facebookLogin = async () => {

    console.log('facebook login');

    ElMessage({
        showClose: true,
        message: "因臉書登入功能審核中，請使用忘記密碼設定後，再輸入帳號密碼登入，謝謝！",
        type: "warning",
        duration: 3000
    });

    return;

    // var status
    // var id_token
    // // 檢查登入狀態
    // await new Promise((resolve) => {
    //     window.FB.getLoginStatus((response) => {
    //         console.log("FB_login-getLoginStatus:", response);
    //         status = response.status;

    //         if (status == 'connected') {
    //             id_token = response.authResponse.accessToken;
    //         }

    //         resolve();
    //     });
    // });

    // await new Promise((resolve, reject) => {
    //     // 登入狀態 - 已登入
    //     if (status === "connected") {
    //         resolve();
    //     } else {
    //         // 登入狀態 - 未登入
    //         // 用戶登入(確認授權)
    //         window.FB.login(
    //             (res) => {
    //                 console.log("FB_login-login:", res);
    //                 id_token = res.authResponse.accessToken;
    //                 if (res.status === "not_authorized" || res.status === "unknown") {
    //                     reject("facebook 授權或登入失敗！");
    //                 }
    //                 resolve();
    //             },
    //             // 授權 - 個人資料&Email
    //             { scope: "public_profile,email" }
    //         );
    //     }
    // });

    // const loading = ElLoading.service({
    //     lock: true,
    //     text: 'Facebook Login...',
    //     background: 'rgba(0, 0, 0, 1)',
    // });

    // const { apiBaseurl } = apiData();

    // await axios({
    //     method: "post",
    //     url: apiBaseurl + "/login",
    //     data: {
    //         id_token: id_token,
    //         mode: "facebook",
    //     }
    // }).then(response => {

    //     const login_response = response.data;
    //     const access_token = login_response.Result.access_token
    //     // Token Expire After 24 hrs
    //     window.$cookies.set('userToken', access_token, "1d", '/');
    //     window.location.reload();

    // }).catch(error => {
    //     const error_msg = error.response.data

    //     ElMessage({
    //         showClose: true,
    //         message: error_msg.Status.Desc,
    //         type: 'error',
    //         duration: 1500
    //     });

    //     loading.close();
    // });

    // console.log(status, id_token);
}

export const discordLogin = async (code) => {

    const { apiBaseurl } = apiData();
    const referral_code = window.$cookies.get("referralCode");

    await axios({
        method: 'post',
        url: apiBaseurl + "/login",
        data: {
            code: code,
            mode: "discord",
            referral_code: referral_code
        }
    }).then(response => {

        const login_response = response.data;
        const access_token = login_response.Result.access_token
        // Token Expire After 24 hrs
        window.$cookies.set('userToken', access_token, "1d", '/');
        window.location.href = '/';

    }).catch(async (error) => {

        const error_data = error.response.data;
        ElMessage({
            showClose: true,
            type: 'error',
            message: error_data.Status.Desc,
            duration: 1500
        });

        await new Promise((resolve) => setTimeout(resolve, 1000));
        window.location.href = `/?referral_code=${referral_code}`;

    })

}

export const lineRedirect = async () => {

    const { apiBaseurl } = apiData();

    await axios({
        method: "get",
        url: apiBaseurl + "/line/login/uri",
    }).then(response => {
        var uri = response.data.result;
        window.location.href = uri;
    })

}

export const loginFunc = (loginToggle) => {

    const commonStore = useCommonStore();
    const userStore = useUserStore();

    if (commonStore.localeSetting == 'en') {
        if (userStore.isWalletConnected) {
            loginToggle = !loginToggle;
        } else {
            const btn = document.getElementsByClassName('swv-button')[0];
            btn.click();
        }
    } else {
        loginToggle = !loginToggle;
    }

    return loginToggle;
}

export const Logout = async () => {
    window.$cookies.remove('lastLogin', '/');
    window.$cookies.remove('userToken', '/');
    window.$cookies.remove('pushToken', '/');
    window.location.reload();
}