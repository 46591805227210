<template>

    <div class="container adsItem margin-bottom-20 margin-top-0" v-if="localeSetting == 'zh-TW'">
        <ins class="adsbygoogle" style="display:block" data-ad-client="ca-pub-2303273940367638" data-ad-slot="6077879106"
            data-ad-format="auto" data-full-width-responsive="true">
        </ins>
    </div>

    <div>
        <footer class="footer">
            <div class="container clearfix">
                <div class="footer-logo">
                    <a href="/" title="TradingTrain 鎚圈">
                        <picture>
                            <source media="(max-width: 991px)" srcset="../assets/images/logo.png" />
                            <img src="../assets/images/logo.png" alt="TradingTrain 鎚圈" />
                        </picture>
                    </a>
                </div>
                <div class="footer-left">
                    <ul class="footer-link notranslate">
                        <li>
                            <router-link to="/about">
                                {{ $t('pageFooter.about') }}
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/privacy">
                                {{ $t('pageFooter.privacy') }}
                            </router-link>
                        </li>
                        <li>
                            <a href="https://www.facebook.com/tdtn.tw" title="聯絡我們" target="_blank">
                                {{ $t('pageFooter.contact') }}
                            </a>
                        </li>
                        <li>
                            <router-link to="/wishing_tree">
                                {{ $t('pageFooter.wishing_tree') }}
                            </router-link>
                        </li>
                    </ul>
                    <div class="copyright notranslate">
                        Copyright © 2022 TradingTrain All rights reserved.
                        <br />版權所有 TradingTrain 鎚圈
                    </div>
                </div>
            </div>
        </footer>
        <div class="topBtn">
            <a href="#top" title="返回最上面" @click.prevent="$scrollTo('body')">
                <img src="../assets/images/topBtn.png" alt="返回最上面">
            </a>
        </div>
        <el-backtop style="background-color:#c5a46e; color:#452f1a"></el-backtop>
    </div>
</template>

<script>
import { useCommonStore } from '@/store';
import { computed } from 'vue';
// @ is an alias to /src
export default {
    name: "FooterArea",
    setup() {
        const commonStore = useCommonStore();
        const localeSetting = computed(() => commonStore.localeSetting);

        return {
            localeSetting
        };
    }
};
</script>

<style scoped>
.footer-link li {
    white-space: nowrap;
}

@media screen and (max-width: 768px) {
    .el-backtop {
        display: none;
    }
}
</style>