<template>
  <div id="content" v-cloak>
    <div id="top"></div>

    <page-header :header-type="`header`"></page-header>

    <article class="main">

      <div class="container">

        <header-ads></header-ads>
        
        <el-row :gutter="30">
          <el-col :md="16">
            <section class="item" v-if="article_data.Announcement">
              <h2 class="tit-type1 hasMoreBtn notranslate" title="公告">
                {{ $t('homePage.title.announcement') }}
                <div class="btn sBtn notranslate">
                  <router-link to="/forum/announcement" title="看更多">
                    {{ $t('homePage.moreText') }} <i class="el-icon-arrow-right"></i>
                  </router-link>
                </div>
              </h2>
              <div class="itemBlock" v-if="article_data.Announcement.length > 0">
                <ul class="newsList">
                  <li v-for="(item, index) in article_data.Announcement" :key="index"
                    style="display: flex; align-items: center;">

                    <div class="newsList-tag margin-right-5" style="word-break: keep-all">
                      {{ item.tags }}
                    </div>

                    <a class="newList-title" href="javascript:void(0);" :title="`${item.title}`"
                      @click="$router.push({ path: `/article/${item.article_id}` })">
                      {{ item.title }}
                    </a>
                    <div class="newsList-time">
                      <i class="el-icon-time"></i> {{ item.Create_Date }}
                    </div>
                  </li>
                </ul>
              </div>
              <div class="itemBlock" v-else>
                <h3>目前尚無公告</h3>
              </div>
            </section>
            <section class="item" v-if="article_data.Latest_Post">
              <h2 class="tit-type1 hasMoreBtn notranslate" title="最新文章">
                {{ $t('homePage.title.latestPost') }}
                <div class="btn sBtn notranslate">
                  <router-link to="/forum" title="看更多">
                    {{ $t('homePage.moreText') }} <i class="el-icon-arrow-right"></i>
                  </router-link>
                </div>
              </h2>
              <div class="itemBlock" v-if="article_data.Latest_Post.length > 0">
                <ul class="newsList2" style="margin-top: 0px;">
                  <li v-for="(item, index) in article_data.Latest_Post" :key="'latest_' + index">
                    <div class="newsList2-img">
                      <router-link :to="`/article/${item.article_id}`">
                        <img :src="`${item.img_url}`" v-if="item.img_url" width="154" height="122">
                        <img src="../assets/images/no_image.jpeg" width="154" height="122" style="opacity: 0.5;" v-else>

                        <div class="newsList2-time">
                          <el-icon class="el-icon-time">
                            <Timer />
                          </el-icon> {{ item.Modified_Date }}
                        </div>
                      </router-link>
                    </div>
                    <div class="newsList2-tit">
                      <router-link :to="`/article/${item.article_id}`">
                        {{ item.title }}
                      </router-link>
                    </div>
                    <div class="newsList2-text">
                      {{ item.abstract }}<span v-if="item.abstract.length > 50">...</span>
                    </div>
                    <div class="newsList2-bottom clearfix">
                      <div class="newsList2-tags">
                        <div class="tag" v-if="!ContainDefaultTag(item.forum_name, item.tags)">
                          <a :href="`/search?q=${item.forum_name}`" :title="`${item.forum_name}`">
                            <span v-if="localeSetting == 'zh-TW'">{{ item.forum_name }}</span>
                            <span v-if="localeSetting == 'en' && item.forum_id == 2">Technical Analysis</span>
                            <span v-if="localeSetting == 'en' && item.forum_id == 3">Market Trend</span>
                            <span v-if="localeSetting == 'en' && item.forum_id == 4">Gossip</span>
                          </a>
                        </div>
                        <div v-for="(t_item, t_index) in item.tags" :key="t_index" class="tag">
                          <a href="javascript:void(0);" :title="`${t_item}`"
                            @click="$router.push({ path: `/search?q=${t_item}` })">
                            {{ t_item }}
                          </a>
                        </div>
                      </div>
                      <div class="newsList2-more">
                        <a href="javascript:void(0);" :title="`${item.title}`"
                          @click="$router.push({ path: `/article/${item.article_id}` })">
                          {{ $t('homePage.readMoreBtn') }}
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="itemBlock" v-else>
                <h3>目前尚無最新文章</h3>
              </div>
            </section>
            <section class="item" v-if="article_data.Hot_Post">
              <h2 class="tit-type1 notranslate" title="熱門文章">
                {{ $t('homePage.title.hotPost') }}
              </h2>
              <div class="itemBlock" v-if="article_data.Hot_Post.length > 0">
                <ul class="newsList3">
                  <li v-for="(item, index) in article_data.Hot_Post" :key='index'>
                    <router-link :to="`/article/${item.article_id}`">
                      {{ item.title }}
                    </router-link>
                    <div class="newsList3-watch">
                      <img class="margin-right-2" src="../assets/images/like.png" width="12" height="12" alt=""> {{
                        item.likes_number }}
                    </div>
                  </li>
                </ul>
              </div>
              <div class="itemBlock" v-else>
                <h3>目前尚無最新文章</h3>
              </div>
            </section>
          </el-col>
          <el-col :md="8">
            <section class="item firstItem" v-if="rank_data">
              <h2 class="tit-type1 hasMoreBtn notranslate" title="排行榜">
                {{ $t('homePage.title.leaderboard') }}
                <div class="btn sBtn notranslate">
                  <router-link to="/leaderboard" title="看更多">
                    {{ $t('homePage.moreText') }} <i class="el-icon-arrow-right"></i>
                  </router-link>
                </div>
              </h2>
              <div class="itemBlock" v-if="rank_data.length > 0">
                <ul class="indexLeaderboard">
                  <li v-for="(item, index) in rank_data" :key='index'>
                    <a :href="`${item.user_id != my_user_id ? '/user/' + item.user_id : 'mypage'}`"
                      :target="windowWidth <= 475 ? '' : '_blank'" class="indexLeaderboard-item">
                      <div class="indexLeaderboard-no notranslate">
                        <img src="../assets/images/icon_crown.png" alt="">
                        {{ $t('homePage.rankText.1') }}
                        <span class="no" v-if="item.rank">{{ item.rank }}</span>
                        <span class="no" v-else>無</span>
                        {{ $t('homePage.rankText.2') }}
                      </div>

                      <img :src="`${item.profile_picture}`" alt="" class="indexLeaderboard-img"
                        v-if="item.profile_picture != ''">
                      <img src="../assets/images/userImg_base.png" alt="" class="indexLeaderboard-img" v-else>

                      <img src="../assets/images/user/藍.png" alt="" class="indexLeaderboard-img2"
                        v-if="item.asset_level == 1" />
                      <img src="../assets/images/user/綠.png" alt="" class="indexLeaderboard-img2"
                        v-if="item.asset_level == 2" />
                      <img src="../assets/images/user/橘.png" alt="" class="indexLeaderboard-img2"
                        v-if="item.asset_level == 3" />
                      <img src="../assets/images/user/黃.png" alt="" class="indexLeaderboard-img2"
                        v-else-if="item.asset_level >= 4" />

                      <div class="indexLeaderboard-info">

                        <div class="indexLeaderboard-name">
                          <div class="username notranslate">{{ item.username }}</div>
                          <span class="asset-level notranslate" v-if="item.asset_level > 0">in{{ item.asset_level
                          }}</span>
                        </div>

                        <div class="indexLeaderboard-money notranslate" style="width: 110px;"
                          :style="index > 2 ? 'right: -30px' : 'right: 0;'">
                          <img src="../assets/images/icon_coin2.png" alt="" class="indexLeaderboard-money-coin1">
                          <img src="../assets/images/icon_coin3.png" alt="" class="indexLeaderboard-money-coin2">
                          {{ item.total_asset }}
                        </div>
                      </div>
                      <div class="indexLeaderboard-icon">
                        <i class="fas fa-minus" v-if="index == 0"></i>
                        <i class="fas fa-caret-up" v-else-if="index == 1 || index == 3 || index == 4"></i>
                        <i class="fas fa-caret-down" v-else></i>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="itemBlock" v-else>
                <h3>尚無排名</h3>
              </div>
            </section>
            <ad-area></ad-area>
          </el-col>
        </el-row>
      </div>
    </article>

    <footer-area></footer-area>
  </div>
</template>

<script>
import pageHeader from '@/components/pageHeader.vue';
import footerArea from '@/components/footerArea.vue';
import AdArea from "@/components/AdArea.vue";
import headerAds from "@/components/headerAds.vue";
import { getHomePage, ContainDefaultTag } from '@/composables';
import { useHomePageStore, useUserStore, useCommonStore } from '@/store';
import { computed, onMounted, watch } from 'vue';

export default {
  name: 'HomeView',
  components: {
    pageHeader,
    footerArea,
    AdArea,
    headerAds
  },
  beforeRouteEnter(to, from, next) {
    const commonStore = useCommonStore();
    commonStore.localeSetting = window.$cookies.get("localeSetting");
    next();
  },
  setup() {
    // 取得使用者id
    const userStore = useUserStore();
    const my_user_id = computed(() => userStore.user_id);

    const homePageStore = useHomePageStore();
    const rank_data = computed(() => homePageStore.rank_data);
    const article_data = computed(() => homePageStore.article_data);

    const commonStore = useCommonStore();
    const windowWidth = computed(() => commonStore.windowWidth);
    const localeSetting = computed(() => commonStore.localeSetting);
    watch(localeSetting, async () => {
      await getHomePage("active");
    });

    onMounted(async () => {
      window.getAds();
      // 取得首頁資訊
      await getHomePage("active");
    })

    return {
      my_user_id,
      rank_data,
      article_data,
      windowWidth,
      localeSetting,
      ContainDefaultTag
    }

  },
}
</script>

<style scoped>
@import url("../assets/css/homepage.css");
</style>
