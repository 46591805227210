import { createApp, provide } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'

import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import ElementPlus from "element-plus";
import "element-plus/theme-chalk/index.css";

import "solana-wallets-vue/styles.css";

import VueCookies from 'vue-cookies';

import vue3GoogleLogin from 'vue3-google-login';

// highcharts module
import HighchartsVue from 'highcharts-vue';

// firebase cloud messaging
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyCn9_wcNvACglTw8dMr81Qs90kzIs_wfdU",
    authDomain: "starry-hawk-333102.firebaseapp.com",
    projectId: "starry-hawk-333102",
    storageBucket: "starry-hawk-333102.appspot.com",
    messagingSenderId: "969354982006",
    appId: "1:969354982006:web:da7e77c5b1f4334b90a557",
    measurementId: "G-0T4TTYPFTG"
};

// Initialize Firebase
try {
    if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        const firebaseApp = initializeApp(firebaseConfig);
        const messaging = getMessaging(firebaseApp);
    }
}
catch(e) {
    console.log(e)
}

const app = createApp(App);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

// quill
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import '@vueup/vue-quill/dist/vue-quill.bubble.css';
import 'quill-mention/dist/quill.mention.min.css';

import Quill from 'quill';
import BlotFormatter from 'quill-blot-formatter';
import MagicUrl from 'quill-magic-url';
import htmlEditButton from "quill-html-edit-button";
import mention from 'quill-mention';

Quill.register("modules/blotFormatter", BlotFormatter);
Quill.register('modules/magicUrl', MagicUrl);
Quill.register("modules/htmlEditButton", htmlEditButton);
Quill.register({'modules/mention': mention});
app.component('QuillEditor', QuillEditor);


// i18n
import { createI18n } from 'vue-i18n';
const i18n = createI18n({
    legacy: false,
    locale: 'zh-TW',
    fallbackLocale: 'zh-TW',
    messages: {
        en: require('./locales/en.json'),
        'zh-TW': require('./locales/zh-TW.json')
    }
});

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    next();
})

app.config.compilerOptions.isCustomElement = tag => tag.startsWith('ins');

app
.use(ElementPlus)
.use(VueCookies)
.use(vue3GoogleLogin, {
    clientId: '969354982006-2nv38fgndur3bif420bgkk3u8vr8kvps.apps.googleusercontent.com',
})
.use(createPinia())
.use(HighchartsVue)
.use(i18n)
.use(router)
.mount('#app')
